import Vue from 'vue'
import VueRouter from 'vue-router'
import overtime from '../views/404.vue'
import abnormal from '../views/alarm/abnormal.vue'
import offline from '../views/alarm/offline.vue'
import renewAlarm from '../views/alarm/renewAlarm.vue'
import equipDetail from '../views/equipment/equipDetail.vue'
import equipHistory from '../views/equipment/equipHistory.vue'
import equipManage from '../views/equipment/equipManage.vue'
import equipTest from '../views/equipment/equipTest.vue'
import home from '../views/home.vue'
import homePage from '../views/homePage.vue'
import login from '../views/login.vue'
import noRenew from '../views/recharge/noRenew.vue'
import renewRecord from '../views/recharge/renewRecord.vue'
import roleControl from '../views/userCenter/roleControl.vue'
import staffControl from '../views/userCenter/staffControl.vue'
import userControl from '../views/userCenter/userControl.vue'

Vue.use(VueRouter)
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: login
  },
  {
    path: '/overtime',
    name: 'overtime',
    meta: {
      title: '登录超时'
    },
    component: overtime
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '主框架'
    },
    component: home,
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        meta: {
          title: '首页'
        },
        component: homePage
      },
      //用户中心
      {
        path: '/userCenter/userControl',
        name: 'userControl',
        meta: {
          title: '用户管理'
        },
        component: userControl
      },
      {
        path: '/userCenter/roleControl',
        name: 'roleControl',
        meta: {
          title: '角色管理'
        },
        component: roleControl
      },
      {
        path: '/userCenter/staffControl',
        name: 'staffControl',
        meta: {
          title: '员工管理'
        },
        component: staffControl
      },
      //报警
      {
        path: '/alarm/abnormal',
        name: 'abnormal',
        meta: {
          title: '异常报警'
        },
        component: abnormal
      },
      {
        path: '/alarm/offline',
        name: 'offline',
        meta: {
          title: '离线报警'
        },
        component: offline
      },
      {
        path: '/alarm/renewAlarm',
        name: 'renewAlarm',
        meta: {
          title: '续费报警'
        },
        component: renewAlarm
      },
      //设备管理
      {
        path: '/equipment/equipManage',
        name: 'equipManage',
        meta: {
          title: '设备管理'
        },
        component: equipManage
      },
      {
        path: '/equipment/equipTest',
        name: 'equipTest',
        meta: {
          title: '测试设备'
        },
        component: equipTest
      },
      {
        path: '/equipment/equipHistory',
        name: 'equipHistory',
        meta: {
          title: '历史数据'
        },
        component: equipHistory
      },
      {
        path: '/equipment/equipDetail',
        name: 'equipDetail',
        meta: {
          title: '设备详情'
        },
        component: equipDetail
      },
      //
      {
        path: '/recharge/renewRecord',
        name: 'renewRecord',
        meta: {
          title: '续费记录'
        },
        component: renewRecord
      },
      {
        path: '/recharge/noRenew',
        name: 'noRenew',
        meta: {
          title: '不续费设备'
        },
        component: noRenew
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
