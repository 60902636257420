import request from "../request"
//查询充值记录
function getPage(data) {
    return request({
        method: 'post',
        url: '/order/page',
        data
    })
}
//导出充值记录
function exportOrderList(data) {
    return request({
        method: 'post',
        url: '/file/exportOrderList',
        data,
        responseType: "blob",
    })
}
export {
    getPage, exportOrderList
}

