import axios from 'axios';
import { Message } from "element-ui";
import router from "../router";
import store from '../store';

const request = axios.create({
    //线上
    // baseURL: 'http://121.41.96.194/api/',
    // baseURL: 'http://39.101.161.95/api/',
    baseURL: 'http://boaodianzi.com.cn/api',
    timeout: 500000     //访问超时的时间限制

});
// request.defaults.withCredentials=true;
//默认请求格式
request.defaults.headers.post['Content-Type'] = 'application/json';
// 添加请求拦截器
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    config.headers.Authorization = store.state.Authorization;
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.headers.authorization) {
        store.state.Authorization = response.headers.authorization
    }
    if (response.data.success == false) {
        Message.error(response.data.msg);
        return Promise.reject()
    } else {
        return response;
    }
}, function (error) {
    // 对响应错误做点什么
    console.log(error)
    Message.error(error.response.data.data)
    if (error.response.status == 401) {
        router.replace('/overtime')
    }
    return Promise.reject(error);
});

export default request;
