import request from "../request"
//获取所有权限
function getAllMenuList(data) {
    return request({
        method: 'post',
        url: '/role/getAllMenuList',
        data
    })

}
//获取当前人权限树形数据
function getPermissionsByRoleId(data) {
    return request({
        method: 'post',
        url: '/role/getPermissionsByRoleId',
        data
    })

}
//重置密码
function reset(data) {
    return request({
        method: 'post',
        url: '/userMst/pwd/reset',
        data
    })

}
//新增角色
function addRole(data) {
    return request({
        method: 'post',
        url: '/role/addRole',
        data
    })

}
//编辑角色
function updateRole(data) {
    return request({
        method: 'post',
        url: '/role/updateRole',
        data
    })

}
//权限配置确认
function permissionConf(data) {
    return request({
        method: 'post',
        url: '/role/permissionConf',
        data
    })

}
//删除角色
function delRole(data) {
    return request({
        method: 'post',
        url: '/role/delRole',
        data
    })

}
export {
    reset, getAllMenuList, getPermissionsByRoleId, addRole, updateRole, permissionConf, delRole
}
