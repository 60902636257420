var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('el-form',{staticClass:"formInline",attrs:{"inline":true,"model":_vm.searchArea,"label-width":"90px"}},[_c('el-row',{attrs:{"gutter":80}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"设备编号："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入设备编号","clearable":""},model:{value:(_vm.searchArea.sn),callback:function ($$v) {_vm.$set(_vm.searchArea, "sn", $$v)},expression:"searchArea.sn"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"测试状态："}},[_c('el-select',{attrs:{"size":"small","placeholder":"请输入测试状态","clearable":""},model:{value:(_vm.searchArea.testStatus),callback:function ($$v) {_vm.$set(_vm.searchArea, "testStatus", $$v)},expression:"searchArea.testStatus"}},[_c('el-option',{attrs:{"label":"测试通过","value":"1"}}),_c('el-option',{attrs:{"label":"待测试","value":"0"}})],1)],1)],1)],1),_c('el-row',{attrs:{"gutter":80}},[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"测试时间："}},[_c('el-date-picker',{attrs:{"size":"small","type":"daterange","picker-options":_vm.pickerOptions,"range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","align":"right"},model:{value:(_vm.searchArea.createTime),callback:function ($$v) {_vm.$set(_vm.searchArea, "createTime", $$v)},expression:"searchArea.createTime"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{staticStyle:{"width":"100% !important"}},[(
              _vm.permissionsList.findIndex(
                function (info) { return info.menuTitle === '列表查询'; }
              ) != -1
            )?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.onSubmit}},[_vm._v("查询")]):_vm._e(),(
              _vm.permissionsList.findIndex(
                function (info) { return info.menuTitle === '列表查询'; }
              ) != -1
            )?_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")]):_vm._e(),(
              _vm.permissionsList.findIndex(
                function (info) { return info.menuTitle === '开启/关闭测试'; }
              ) != -1
            )?_c('span',[(_vm.test === true)?_c('el-button',{staticStyle:{"margin-left":"30px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.testOn}},[_vm._v("开始测试")]):_c('el-button',{staticStyle:{"margin-left":"30px"},attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.testOff()}}},[_vm._v("关闭测试")])],1):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"table roleTable"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"header-cell-style":{ background: 'rgb(250,250,250)' },"data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"sn","label":"设备编号","align":"center","min-width":"100"}}),_c('el-table-column',{attrs:{"label":"测试状态","align":"center","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.testStatus === 1)?_c('div',[_c('span',{staticStyle:{"color":"#00a854"}},[_vm._v("●")]),_vm._v(" 测试通过 ")]):(scope.row.testStatus === 0)?_c('div',[_c('span',{staticStyle:{"color":"#f04134"}},[_vm._v("●")]),_vm._v(" 待测试 ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"测试时间","align":"center","min-width":"100"}}),_c('el-table-column',{attrs:{"label":"操作","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.testStatus === 0 &&
              _vm.permissionsList.findIndex(
                function (info) { return info.menuTitle === '测试'; }
              ) != -1
            )?_c('el-button',{attrs:{"size":"mini","type":"primary","round":"","plain":""},on:{"click":function($event){return _vm.testOnOff(scope.row)}}},[_vm._v("测试 ")]):_vm._e(),(
              scope.row.testStatus === 1 &&
              _vm.permissionsList.findIndex(
                function (info) { return info.menuTitle === '入库'; }
              ) != -1
            )?_c('el-button',{attrs:{"size":"mini","type":"primary","round":"","plain":""},on:{"click":function($event){return _vm.putInStorage(scope.row)}}},[_vm._v("入库 ")]):_vm._e(),(
              _vm.permissionsList.findIndex(
                function (info) { return info.menuTitle === '删除'; }
              ) != -1
            )?_c('el-button',{attrs:{"type":"danger","size":"mini","round":"","plain":""},on:{"click":function($event){return _vm.removeById(scope.row)}}},[_vm._v("删除 ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.searchArea.current,"page-sizes":[5, 10, 15, 20],"page-size":_vm.searchArea.size,"background":"","layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }