import request from "../request"
//设备统计
function equ(data) {
    return request({
        method: 'post',
        url: '/index/equ',
        data
    })
}
//报警统计
function alarm(data) {
    return request({
        method: 'post',
        url: '/index/alarm',
        data
    })
}
//用户、费用统计
function userCost(data) {
    return request({
        method: 'post',
        url: '/index/userCost',
        data
    })
}

export {
    equ, alarm, userCost
}

