<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <div class="flex-between">
      <el-tabs
        class="flex-start"
        v-model="activeName"
        type="card"
        @tab-click="handleClick(activeName)"
      >
        <el-tab-pane label="超期未激活" name="first">
          <div style="margin-left: 30px">
            <el-form
              :inline="true"
              :model="searchArea"
              class="formInline"
              label-width="120px"
            >
              <el-row :gutter="100">
                <el-col :span="4">
                  <el-form-item
                    ><el-button
                      icon="el-icon-download"
                      type="primary"
                      size="small"
                      v-if="
                        permissionsList.findIndex(
                          (info) => info.menuTitle === '导出'
                        ) != -1
                      "
                      @click="download"
                      >Excel导出</el-button
                    ></el-form-item
                  >
                </el-col>
              </el-row>
            </el-form>
          </div></el-tab-pane
        >
        <el-tab-pane label="超期未续费" name="second">
          <div style="margin-left: 30px">
            <el-form
              :inline="true"
              :model="searchArea"
              class="formInline"
              label-width="120px"
            >
              <el-row :gutter="100">
                <el-col :span="4">
                  <el-form-item
                    ><el-button
                      icon="el-icon-download"
                      type="primary"
                      size="small"
                      v-if="
                        permissionsList.findIndex(
                          (info) => info.menuTitle === '导出'
                        ) != -1
                      "
                      @click="download"
                      >Excel导出</el-button
                    ></el-form-item
                  >
                </el-col>
              </el-row>
            </el-form>
          </div></el-tab-pane
        >
      </el-tabs>
    </div>
    <!--        表格区域-->
    <div class="table roleTable">
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="sn"
          label="设备编号"
          align="center"
          min-width="100"
        >
          <template slot-scope="scope">
            <a @click="goDetail(scope.row)">{{ scope.row.sn }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="monitorName"
          label="监控点名称"
          align="center"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          align="center"
          min-width="100"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isExpire === 0 && scope.row.status === 1">
              在线
            </div>
            <div v-else-if="scope.row.isExpire === 0 && scope.row.status === 0">
              离线
            </div>
            <div v-else-if="scope.row.isExpire === 1">欠费</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="isExpire"
          label="是否到期"
          align="center"
          min-width="100"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isExpire === 1">已到期</div>
            <div v-else-if="scope.row.isExpire === 0">未到期</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="isRenewal"
          label="能否续费"
          align="center"
          min-width="100"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isRenewal === 1">能</div>
            <div v-else-if="scope.row.isRenewal === 0">否</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sim"
          label="SIM卡号"
          align="center"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="入库时间"
          align="center"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="activateTime"
          label="激活时间"
          align="center"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="expirationTime"
          label="到期时间"
          align="center"
          min-width="100"
        >
        </el-table-column>
      </el-table>
    </div>
    <!--        分页区域-->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchArea.current"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="searchArea.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getNoRenewalList,
  exportNoRenewalList,
} from "@/tools/recharge/noRenew";

export default {
  name: "equipTest",
  data() {
    return {
      //查询数据
      searchArea: {
        //表格宽度
        size: 5,
        //当前多少页
        current: 1,
        type: 1,
      },
      //表格数据
      tableData: [],
      //表格数据总数
      total: 0,
      //表格loading
      loading: false,
      activeName: "first",
      permissionsList: [],
    };
  },
  created() {
    this.$store.state.nowName = "不续费设备";
    this.permissionsList = this.$store.state.permissionsList;
    console.log(this.permissionsList);
    this.getTableData();
  },
  methods: {
    handleClick(name) {
      console.log(name);
      if (name === "first") {
        this.searchArea.type = 1;
        this.getTableData();
      } else if (name === "second") {
        this.searchArea.type = 2;
        this.getTableData();
      }
    },
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      this.getTableData();
    },
    goDetail(row) {
      this.$store.state.equipInfo = row;
      this.$router.push("../equipment/equipDetail");
    },
    download() {
      let fileName = "";
      if (this.searchArea.type === 1) {
        fileName = "超期未激活记录.xls";
      } else {
        fileName = "超期未续费记录.xls";
      }
      exportNoRenewalList({ type: this.searchArea.type }).then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // for Non-IE (chrome, firefox etc.)
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          console.log(elink.href);
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      });
    },
    //获取表格数据
    getTableData() {
      getNoRenewalList({ ...this.searchArea }).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.tableData = [];
          if (res.data.data != null) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      this.loading = true;
      this.getTableData();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      this.loading = true;
      this.getTableData();
    },
  },
};
</script>

<style scoped>
/*搜素区域距离底部距离*/
.el-row {
  margin-bottom: 20px;

  & :last-child {
    margin-bottom: 0;
  }
}

/*设置form表单子元素宽度*/
.content-wrapper >>> .el-form--inline .el-form-item__content {
  width: 75% !important;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}

/*表格class*/
.roleTable {
  /*height: 70%;*/
}
>>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-bottom: 1px solid #e4e7ed;
}
>>> .el-tabs--card > .el-tabs__header {
  border: none;
}
</style>
