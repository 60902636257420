<template>
  <div class="wrapper">
    <div class="bg"></div>
    <div class="button">
      <div
        style="
          font-family: 'Arial Normal', 'Arial', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 90px;
          margin-bottom: 20px;
        "
      >
        401
      </div>
      <div
        style="
          font-family: 'MicrosoftYaHei', 'Microsoft YaHei', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 40px;
          color: rgba(0, 0, 0, 0.427450980392157);
          line-height: 28px;
          margin-bottom: 50px;
        "
      >
        当前登录已超时，请重新登录
      </div>
      <el-button type="primary" @click="goLogin">返回登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "overtime",
  methods: {
    goLogin() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
}
.bg {
  position: absolute;
  background: url("../assets/over.png") no-repeat;
  background-size: 100% 100%;
  width: 659px;
  height: 517px;
  top: 20%;
  left: 15%;
}
.button {
  width: 30%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}
</style>
