<template>
  <div class="content-wrapper" v-cloak>
    <div class="title">基本信息</div>
    <div class="info">
      <el-row :gutter="20" class="rowStyle">
        <el-col :span="8">
          设备编号：<span
            class="info-content"
            v-if="info.sn && info.sn != ''"
            >{{ info.sn }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
        <el-col :span="8">
          监控点名称：<span
            class="info-content"
            v-if="info.monitorName && info.monitorName != ''"
            >{{ info.monitorName }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
        <el-col :span="8">
          型号：<span class="info-content" v-if="info.equipmentType == '1'"
            >4-LOT</span
          >
          <span class="info-content" v-else-if="info.equipmentType == '2'"
            >Q3</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowStyle">
        <el-col :span="8">
          状态：<span class="info-content" v-if="info.status == 1"
            ><span style="color: #5ac790">●</span>在线</span
          >
          <span class="info-content" v-else-if="info.status == 2"
            ><span style="color: #f04134">●</span>离线</span
          >
          <span class="info-content" v-else-if="info.status == 0"
            ><span style="color: #f39c12">●</span>未激活</span
          >
        </el-col>
        <el-col :span="8">
          大小m³：<span
            class="info-content"
            v-if="info.volume && info.volume != ''"
            >{{ info.volume }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
        <el-col :span="8">
          是否到期：<span class="info-content" v-if="info.isExpire == 0"
            >未到期</span
          >
          <span class="info-content" v-else-if="info.isExpire == 1"
            >已到期</span
          >
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowStyle">
        <el-col :span="8">
          入库时间：<span
            class="info-content"
            v-if="info.createTime && info.createTime != ''"
            >{{ info.createTime }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
        <el-col :span="8">
          激活时间：<span
            class="info-content"
            v-if="info.activateTime && info.activateTime != ''"
            >{{ info.activateTime }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
        <el-col :span="8">
          到期时间：<span
            class="info-content"
            v-if="info.expirationTime && info.expirationTime != ''"
            >{{ info.expirationTime }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="rowStyle">
        <el-col :span="8">
          最近一次采集时间：<span
            class="info-content"
            v-if="info.inTime && info.inTime != ''"
            >{{ info.inTime }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
        <el-col :span="8">
          SIM卡号：<span
            class="info-content"
            v-if="info.sim && info.sim != ''"
            >{{ info.sim }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
        <el-col :span="8">
          能否续费：<span class="info-content" v-if="info.isRenewal == 0"
            >否</span
          >
          <span class="info-content" v-else-if="info.isRenewal == 1">能</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowStyle">
        <el-col :span="8">
          说明：<span
            class="info-content"
            v-if="info.instructions && info.instructions != ''"
            >{{ info.instructions }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowStyle">
        <el-col :span="8">
          备注：<span
            class="info-content"
            v-if="info.note && info.note != ''"
            >{{ info.note }}</span
          >
          <span v-else class="info-content">-</span>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <div class="title">操作记录</div>
    <el-table
      :header-cell-style="{ background: 'rgb(250,250,250)' }"
      :data="tableData"
      stripe
      v-loading="loading"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="100"> </el-table-column>
      <el-table-column prop="nikeName" label="姓名" min-width="120">
      </el-table-column>
      <el-table-column prop="phoneNo" label="手机号码" min-width="120">
      </el-table-column>
      <el-table-column label="权限" min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.role == 0">待分配</div>
          <div v-else-if="scope.row.role == 1">管理员</div>
          <div v-else-if="scope.row.role == 2">助理</div>
          <div v-else-if="scope.row.role == 3">员工</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" min-width="50">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="edit(scope.row)"
            >权限配置</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <div class="button">
      <el-button size="medium" @click="goBack">返回</el-button>
      <el-button
        size="medium"
        v-if="
          permissionsList.findIndex((info) => info.menuTitle === '权限配置') !=
          -1
        "
        type="primary"
        @click="edit()"
        >权限配置</el-button
      >
      <el-button
        size="medium"
        v-if="
          permissionsList.findIndex((info) => info.menuTitle === '返厂重置') !=
          -1
        "
        type="danger"
        plain
        @click="clear"
        >返厂重置</el-button
      >
    </div>
    <el-dialog
      title="权限配置"
      :visible.sync="editVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="editCancel"
    >
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        :data="tableData"
        header-align="center"
        stripe
        v-loading="loading"
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="nikeName" label="姓名" min-width="50">
        </el-table-column>
        <el-table-column prop="limit" label="权限" min-width="80">
          <template slot-scope="scope">
            <el-select size="small" v-model="scope.row.role">
              <el-option
                v-for="item in role"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.role !== 1"
              size="mini"
              type="danger"
              round
              plain
              @click="del(scope.$index, tableData)"
              >删除</el-button
            >
            <div v-else>&nbsp;&nbsp;&nbsp;-</div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editCancel()">取 消</el-button>
        <el-button type="primary" @click="editSure()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { clear, query, querySave } from "../../tools/equipment/equipManage";
export default {
  name: "equipmentDetail",
  data() {
    return {
      //当前页面展示的信息
      info: {},
      //表格数据
      tableData: [],
      roleData: [
        {
          nikeName: "",
          role: "",
        },
      ],
      pageNum: 1,
      pageSize: 5,
      total: 0,
      editVisible: false,
      loading: true,
      role: [
        {
          label: "待分配",
          value: 0,
        },
        {
          label: "管理员",
          value: 1,
        },
        {
          label: "助理",
          value: 2,
        },
        {
          label: "员工",
          value: 3,
        },
      ],
      permissionsList: [],
    };
  },
  created() {
    this.$store.state.nowName = "设备详情";
    this.info = this.$store.state.equipInfo;
    this.permissionsList = this.$store.state.permissionsList;
    this.query();
  },
  methods: {
    clear() {
      this.$confirm("确认返厂重置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clear(this.info.id).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.query();
              this.$message.success("返厂重置成功！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消返厂重置",
          });
        });
    },
    del(index, rows) {
      rows.splice(index, 1);
      console.log(index);
    },
    edit() {
      this.query();
      this.editVisible = true;
    },
    editSure() {
      console.log(this.tableData);
      querySave(this.tableData).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.query();
          this.$message.success("修改成功！");
          this.editVisible = false;
        }
      });
    },
    editCancel() {
      this.query();
      this.editVisible = false;
    },
    query() {
      query({
        equId: this.info.id,
      }).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
          this.loading = false;
        } else {
          this.tableData = res.data.data;
          this.loading = false;
        }
      });
    },
    //返回上一页
    goBack() {
      // this.$router.push("/equipment/equipManage");
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
/*步骤条样式*/
.steps {
  margin-top: 30px;
  width: 100%;
}

/*小标题样式*/
.title {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
}

/*基本信息*/
.info {
  font-size: 15px;
  color: rgb(49, 49, 51);
}

/*基本信息每行样式*/
.rowStyle {
  margin: 15px 0;
}

/*详细内容样式*/
.info-content {
  color: #666;
}

/*底部button样式*/
.button {
  width: 100%;
  margin-top: 120px;
  text-align: center;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}
</style>
