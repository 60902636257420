<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <el-form
      :inline="true"
      :model="searchArea"
      class="formInline"
      label-width="80px"
    >
      <el-row :gutter="80">
        <el-col :span="5">
          <el-form-item label="姓名：">
            <el-input
              v-model="searchArea.nikeName"
              size="small"
              placeholder="请输入姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="角色：">
            <el-select
              v-model="searchArea.roleId"
              size="small"
              placeholder="请选择角色"
              clearable
            >
              <el-option
                v-for="item in roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="5">
          <el-form-item label="手机号码：">
            <el-input
              v-model="searchArea.phone"
              size="small"
              placeholder="请输入手机号码"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="状态：">
            <el-select
              v-model="searchArea.isEnable"
              size="small"
              placeholder="请输入状态"
              clearable
            >
              <el-option
                v-for="item in isEnableData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmit"
              >查询</el-button
            >
            <el-button size="small" @click="reset">重置</el-button>
            <el-button
              style="margin-left: 40px"
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click="userControl('add')"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '新增员工'
                ) != -1
              "
              >新增员工</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--        表格区域-->
    <div class="table">
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        :data="tableData"
        v-loading="loading"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="userName" label="用户名" min-width="150">
        </el-table-column>
        <el-table-column prop="nikeName" label="姓名" min-width="150">
        </el-table-column>
        <el-table-column prop="sex" label="性别" min-width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.sex === 1">男</div>
            <div v-else-if="scope.row.sex === 0">女</div>
          </template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色" min-width="150">
        </el-table-column>
        <el-table-column prop="phoneNo" label="手机号码" min-width="150">
        </el-table-column>
        <el-table-column prop="note" label="备注" min-width="150">
        </el-table-column>
        <el-table-column prop="isEnable" label="状态" min-width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.isEnable === 1">
              <span style="color: #00a854">●</span> 启用
            </div>
            <div v-else><span style="color: #f04134">●</span> 停用</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '编辑员工'
                ) != -1
              "
              @click="userControl('edit', scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '删除员工'
                ) != -1
              "
              @click="userDelete(scope.$index, scope.row)"
              >删除
            </el-button>
            <span style="margin-left: 10px">
              <el-popover placement="bottom" width="10" trigger="hover">
                <div
                  style="text-align: center"
                  v-if="
                    permissionsList.findIndex(
                      (info) => info.menuTitle === '启用员工'
                    ) != -1
                  "
                >
                  <el-button
                    v-if="scope.row.isEnable === 0"
                    size="mini"
                    type="text"
                    @click="changeStatus(1, '启用', scope.row)"
                    >启用</el-button
                  >
                  <el-button
                    v-else
                    size="mini"
                    type="text"
                    @click="changeStatus(0, '停用', scope.row)"
                    >停用</el-button
                  >
                </div>
                <div style="text-align: center">
                  <el-button
                    size="mini"
                    type="text"
                    v-if="
                      permissionsList.findIndex(
                        (info) => info.menuTitle === '重置员工密码'
                      ) != -1
                    "
                    @click="resetPassword(scope.row)"
                    >重置密码</el-button
                  >
                </div>
                <el-button
                  size="mini"
                  type="primary"
                  round
                  plain
                  slot="reference"
                  >更多</el-button
                >
              </el-popover>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--        分页区域-->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchArea.current"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="searchArea.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="userTitle"
      :visible.sync="userVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="userHandleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名：" prop="userName">
          <el-input
            v-model="ruleForm.userName"
            size="small"
            placeholder="请输入用户名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="nikeName">
          <el-input
            v-model="ruleForm.nikeName"
            size="small"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="roleId">
          <el-select
            v-model="ruleForm.roleId"
            size="small"
            placeholder="请选择角色"
            clearable
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-select
            v-model="ruleForm.sex"
            size="small"
            placeholder="请选择性别"
            clearable
          >
            <el-option
              v-for="item in sexData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号：" prop="phoneNo">
          <el-input
            v-model="ruleForm.phoneNo"
            size="small"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密码："
          prop="password"
          v-if="this.userTitle === '新增用户'"
        >
          <el-input
            v-model="ruleForm.password"
            size="small"
            placeholder="请输入密码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码："
          prop="surePassword"
          v-if="this.userTitle === '新增用户'"
        >
          <el-input
            v-model="ruleForm.surePassword"
            size="small"
            placeholder="请确认密码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="ruleForm.note"
            size="small"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userCancel('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="userSure('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPageList as roleList } from "@/tools/userCenter/roleControl";
import { reset } from "@/tools/userCenter/staffControl";
import { add, del, getPageList, updateP } from "@/tools/userCenter/userControl";

export default {
  name: "userControl",
  data() {
    var phoneSure = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号码"));
      }
      setTimeout(() => {
        let reg =
          /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/;
        if (!reg.test(value)) {
          callback(new Error("请输入正确手机号码"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      //查询数据
      searchArea: {
        nikeName: "",
        phone: "",
        isEnable: "",
        roleId: "",
        userType: 2,
        //表格宽度
        size: 5,
        //当前多少页
        current: 1,
      },
      //起止时间数据控制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      //
      isEnableData: [
        {
          label: "启用",
          value: 1,
        },
        {
          label: "停用",
          value: 0,
        },
      ],
      //
      sexData: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 0,
        },
      ],
      //角色选择框数据
      roleOptions: [],
      //表格数据
      tableData: [],
      //表格数据总数
      total: 0,
      //新增/编辑用户 弹窗title
      userTitle: "",
      //新增/编辑用户 弹窗显示
      userVisible: false,
      //新增/编辑用户 弹窗内数据
      ruleForm: {
        userName: "",
        nikeName: "",
        roleId: "",
        phoneNo: "",
        sex: "",
        note: "",
        id: "",
        password: "",
        surePassword: "",
      },
      //新增/编辑用户 数据规则
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        nikeName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        phoneNo: [{ validator: phoneSure, required: true, trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        surePassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
        ],
      },
      //表格loading
      loading: false,
      permissionsList: [],
    };
  },
  created() {
    this.$store.state.nowName = "员工管理";
    this.getTableData();
    this.getRoleData();
    this.permissionsList = this.$store.state.permissionsList;
  },
  methods: {
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      this.getTableData();
    },
    //重置查询参数
    reset() {
      this.searchArea.isEnable = "";
      this.searchArea.roleId = "";
      this.searchArea.nikeName = "";
      this.searchArea.phone = "";
    },
    //获取表格数据
    getTableData() {
      let data = { ...this.searchArea };
      getPageList(data).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.tableData = [];
          if (res.data.data != null) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    //获取角色数据
    getRoleData() {
      let data = {
        size: -1,
        roleName: "",
      };
      roleList(data).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          let array = res.data.data.records;
          for (let i in array) {
            this.roleOptions.push({
              label: array[i].roleName,
              value: array[i].id,
            });
          }
        }
      });
    },
    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      this.loading = true;
      this.getTableData();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      this.loading = true;
      this.getTableData();
    },
    //新增/编辑用户弹窗右上角关闭
    userHandleClose(done) {
      this.clear();
      done();
    },
    //编辑用户点击
    userControl(type, row) {
      if (type === "add") {
        this.userTitle = "新增用户";
        this.userVisible = true;
      } else {
        this.userTitle = "编辑用户";
        this.ruleForm.userName = row.userName;
        this.ruleForm.nikeName = row.nikeName;
        this.ruleForm.roleId = row.roleId;
        this.ruleForm.phoneNo = row.phoneNo;
        this.ruleForm.note = row.note;
        this.ruleForm.sex = row.sex;
        this.ruleForm.password = row.password;
        this.ruleForm.id = row.id;
        this.userVisible = true;
      }
    },
    //加密 base64
    encode(str) {
      if (str === undefined || str === "" || str === null) {
        return str;
      }
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    //清空新增/编辑用户弹窗数据
    clear() {
      //异步操作
      setTimeout(() => {
        this.$refs["ruleForm"].clearValidate();
      }, 100);
      this.ruleForm.userName = "";
      this.ruleForm.roleId = "";
      this.ruleForm.nikeName = "";
      this.ruleForm.phoneNo = "";
      this.ruleForm.sex = "";
      this.ruleForm.note = "";
      this.ruleForm.id = "";
      this.ruleForm.password = "";
      this.ruleForm.surePassword = "";
    },
    //新增/编辑用户弹窗取消
    userCancel() {
      this.clear();
      this.userVisible = false;
    },
    //新增/编辑用户弹窗确定
    userSure(formName) {
      //节流
      //没有创建上来就为假，偷懒了不想每个都创建
      if (this.canRun) {
        return;
      }
      //变成真下一次点就会return
      this.canRun = true;
      //1秒钟后变成假
      setTimeout(() => {
        this.canRun = false;
      }, 4000);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = { ...this.ruleForm };
          if (this.userTitle === "新增用户") {
            if (data.password !== data.surePassword) {
              this.$message.error("两次密码不一致，请重新输入！");
              this.ruleForm.surePassword = "";
              return;
            }
            data.password = this.encode(data.password);
            add(data).then((res) => {
              if (res.data.msg != "success") {
                this.$message.error(res.data.msg);
              } else {
                this.$message({
                  message: "新增成功!",
                  type: "success",
                });
                this.loading = true;
                this.getTableData();
                this.clear();
                this.userVisible = false;
              }
            });
          } else {
            updateP(data).then((res) => {
              if (res.data.msg != "success") {
                this.$message.error(res.data.msg);
              } else {
                this.$message({
                  message: "编辑成功!",
                  type: "success",
                });
                this.loading = true;
                this.getTableData();
                this.clear();
                this.userVisible = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //删除用户
    userDelete(index, row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({
            id: row.id,
          }).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loading = true;
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //启用/停用用户
    changeStatus(type, typeName, row) {
      this.$confirm("确认" + typeName + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateP({
            id: row.id,
            roleId: row.roleId,
            phoneNo: row.phoneNo,
            nikeName: row.nikeName,
            sex: row.sex,
            userName: row.userName,
            isEnable: type,
          }).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.$message({
                type: "success",
                message: typeName + "成功!",
              });
              this.loading = true;
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    //重置密码
    resetPassword(row) {
      console.log(row);
      this.$confirm("确认将密码重置为123456?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          reset({
            id: row.id,
            roleId: row.roleId,
            phoneNo: row.phoneNo,
            nikeName: row.nikeName,
            sex: row.sex,
            userName: row.userName,
          }).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.$message({
                type: "success",
                message: "重置成功!",
              });
              if (row.id == this.$store.state.userInfo.user.id) {
                // logout({}).then((res) => {
                //   if (res.data.msg != "success") {
                //     this.$message.error(res.data.msg);
                //   } else {
                //     this.$store.state.defaultActive = "";
                //     this.$router.push("/");
                //   }
                // });
                this.$router.push("/");
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
  },
};
</script>

<style scoped>
/*搜素区域距离底部距离*/
.el-row {
  margin-bottom: 20px;

  & :last-child {
    margin-bottom: 0;
  }
}

/*设置form表单子元素宽度*/
.content-wrapper >>> .el-form--inline .el-form-item__content {
  width: 75% !important;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}
</style>
