<template>
  <div id="app" v-cloak>
    <router-view />
  </div>
</template>

<script>
import { datectZoom } from "./components/datectZoom";
export default {
  name: "app",
  data() {
    return {
      screenWidth: null,
    };
  },
  mounted() {
    //解决vuex刷新丢失的问题
    window.addEventListener("unload", this.saveState);
    //缩放问题
    // this.screenWidth = document.body.clientWidth;
    // window.onresize = () => {
    //   return (() => {
    //     this.screenWidth = document.body.clientWidth;
    //   })();
    // };
  },
  methods: {
    //解决vuex刷新丢失的问题
    saveState() {
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    },
  },
  watch: {
    // screenWidth: {
    //   handler: function (val) {
    //     if (val) {
    //       // 处理笔记本系统默认系统比例为150%带来的布局影响
    //       const m = datectZoom();
    //       console.log(document.body.getElementsByClassName("wrapper"));
    //       document.body.style.zoom = 100 / Number(m);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei" !important;
}

/*vue设置初始隐藏未加载数据*/
[v-cloak] {
  display: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

/*全局flex  css*/
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/*全局flex  css*/
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*全局树形左右样式*/
.measurementFlex {
  display: flex;
  justify-content: space-between;
}

/*全局flex  css*/
.flex-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/*全局flex  css*/
.flex-start {
  display: flex;
  justify-content: flex-start;
  overflow: auto;
}

/*全局鼠标hovercss*/
.cursor {
  cursor: pointer;
}

/*全局外部遮罩层css*/
.content-wrapper {
  padding: 2% 2%;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

/*全局表格css*/
.table {
  /*height: 60%;*/
  overflow: auto;
}

/*全局分页css*/
.el-pagination {
  margin: 5%;
}
.el-pagination {
  display: flex;
  justify-content: center;
}

/*form表单不换行*/
.formInline {
  white-space: nowrap;
}

/*a标签去掉下划线*/
a {
  text-decoration: none;
  color: rgb(24, 144, 255);
  cursor: pointer;
}

/*修改高德地图输入提示框z-index等级，不然会被element 压住看不见*/
.amap-sug-result {
  z-index: 9999 !important;
}

/*根据产品要求修改el-dialog样式*/
.el-dialog__body {
  padding: 30px 40px !important;
}

/*根据产品要求修改el-dialog样式*/
.el-dialog__header {
  border-bottom: 1px solid #ddd;
}

/*根据产品要求修改el-dialog样式*/
.el-dialog__footer {
  border-top: 1px solid #ddd;
  padding-top: 20px !important;
}

/*左侧菜单栏样式修改套件*/
.el-submenu__title {
  background-color: rgb(0, 21, 41) !important;
}

.el-submenu .el-menu-item {
  background-color: rgb(0, 12, 23) !important;
}

.el-menu-item:hover {
  background-color: rgb(24, 144, 255) !important;
}

.el-menu-item.is-active {
  /*background-color: rgb(24, 144, 255) !important;*/
}

.el-menu-item-group__title {
  background-color: rgb(0, 12, 23) !important;
  display: none;
}

.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  color: white;
}

/*解决表格闪烁得问题*/
.el-table {
  width: 98% !important;
}

/*隐藏但依然占位*/
.visibility {
  visibility: hidden;
}

/*设置弹出框内select宽度为100%*/
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100% !important;
}
/*滚动样式*/
.el-scrollbar {
  height: 100%;
}

/*隐藏横向滚动条*/
>>> .el-scrollbar__wrap {
  overflow-x: hidden !important;
  padding-bottom: 17px;
}
.printTitle {
  padding-right: 30mm;
  box-sizing: border-box;
}
.printLogo {
  width: 55mm;
  height: 20mm;
}
.printWord {
  font-size: 30px;
  font-weight: bold;
  color: black;
  padding: 10px 0;
  border-bottom: 3px solid black;
}
.printOut {
  height: 400px;
  width: 210mm;
}

.printMain {
  height: 148mm;
  width: 210mm;
  padding-top: 5%;
  box-sizing: border-box;
}
.printContent {
  height: calc(100% - 20mm);
  width: 210mm;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media print {
  .printOut {
    height: auto !important;
    width: 210mm !important;
  }
  .printMain {
    height: 141.56mm !important;
    width: 210mm !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

/*tree 得加减号图标样式*/
.el-icon-caret-right:before {
  content: url("./assets/add.png") !important;
  font-size: 8px;
}

.expanded:before {
  content: url("./assets/cancel.png") !important;
  transform: rotate(-90deg);
  font-size: 8px;
}

.is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: "" !important;
  font-size: 16px;
}

.tree-container /deep/ .el-tree > .el-tree-node:after {
  border-top: none;
}

.el-tree-node {
  position: relative;
  padding-left: 16px;
}

.el-tree-node__children {
  padding-left: 16px;
}

.el-tree-node :last-child:before {
  height: 38px;
}

.el-tree > .el-tree-node:before {
  border-left: none;
}

.tree-container /deep/ .el-tree > .el-tree-node:after {
  border-top: none;
}

.el-tree-node:before {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.el-tree-node:after {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.el-tree-node:before {
  border-left: 1px dashed #4386c6;
  bottom: 0;
  height: 100%;
  top: -26px;
  width: 1px;
}

.el-tree-node:after {
  border-top: 1px dashed #4386c6;
  height: 20px;
  top: 12px;
  width: 24px;
}

.el-popover {
  min-width: 100px;
}
</style>

