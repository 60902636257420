<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <el-form
      :inline="true"
      :model="searchArea"
      class="formInline"
      label-width="90px"
    >
      <el-row :gutter="80">
        <el-col :span="6">
          <el-form-item label="角色名称：">
            <el-input
              v-model="searchArea.roleName"
              size="small"
              placeholder="请输入角色名称"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item style="width: 100% !important">
            <el-button type="primary" size="small" @click="onSubmit"
              >查询</el-button
            >
            <el-button size="small" @click="reset">重置</el-button>
            <el-button
              style="margin-left: 40px"
              type="primary"
              size="small"
              icon="el-icon-plus"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '新增角色'
                ) != -1
              "
              @click="roleControl('add')"
              >新增角色
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--        表格区域-->
    <div class="table roleTable">
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="roleName"
          label="角色名称"
          align="center"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="roleDesc"
          label="角色描述"
          align="center"
          min-width="300"
        >
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '编辑角色'
                ) != -1
              "
              @click="roleControl('edit', scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '删除角色'
                ) != -1
              "
              @click="roleDelete(scope.$index, scope.row)"
              >删除
            </el-button>
            <el-button
              size="mini"
              type="primary"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '权限配置'
                ) != -1
              "
              @click="permissionSetting(scope.$index, scope.row)"
              >权限设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--        分页区域-->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchArea.current"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="searchArea.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="roleTitle"
      :visible.sync="roleVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="roleHandleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="角色名称：" prop="roleName">
          <el-input size="small" v-model="ruleForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述：">
          <el-input type="textarea" v-model="ruleForm.roleDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleCancel('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="roleSure('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="权限设置"
      :visible.sync="permissionVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="permissionHandleClose"
    >
      <div style="height: 60vh; overflow: auto">
        <div ref="treeDiv" class="tree-container">
          <el-tree
            ref="menu"
            id="tree"
            class="tree"
            :indent="0"
            :data="treeData"
            show-checkbox
            default-expand-all
            node-key="id"
            :props="treeProps"
          >
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="permissionCancel">取 消</el-button>
        <el-button type="primary" @click="permissionSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPageList,
  save,
  update,
  delRole,
  menus,
  build,
} from "@/tools/userCenter/roleControl";

export default {
  name: "roleControl",
  data() {
    return {
      //查询数据
      searchArea: {
        roleName: "",
        //表格宽度
        size: 8,
        //当前多少页
        current: 1,
      },
      //表格数据
      tableData: [],
      //表格数据总数
      total: 0,
      //新增/编辑角色 弹窗title
      roleTitle: "",
      //新增/编辑角色 弹窗显示
      roleVisible: false,
      //权限 弹窗显示
      permissionVisible: false,
      //编辑时使用的当前选择角色的roleId
      roleId: "",
      //新增/编辑角色 弹窗内数据
      ruleForm: {
        id: "",
        roleName: "",
        roleDesc: "",
      },
      //新增/编辑角色 数据规则
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        roleDesc: [
          { required: true, message: "请输入角色描述", trigger: "change" },
        ],
      },
      //tree 用来命名label 和 children的属性名
      treeProps: {
        label: "menuTitle",
        children: "childList",
      },
      //权限树形的data
      treeData: [],
      //默认当前角色已选择的权限数组
      checked: [],
      //修改后选择的权限
      menuIds: [],
      //当前选择的角色id
      nowRoleId: "",
      //当前选中的tree层级
      nowLevel: 1,
      //表格loading
      loading: false,
      permissionsList: [],
    };
  },
  created() {
    this.$store.state.nowName = "角色管理";
    this.permissionsList = this.$store.state.permissionsList;

    this.getTableData();
  },
  methods: {
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      this.getTableData();
    },
    //重置查询参数
    reset() {
      this.searchArea.roleName = "";
    },
    //获取表格数据
    getTableData() {
      getPageList({ ...this.searchArea }).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.tableData = [];
          if (res.data.data != null) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    //权限编辑
    permissionSetting(index, row) {
      this.getTreeData(row);
      this.ruleForm.id = row.id;
    },
    //获取当前人权限树形数据
    getTreeData(row) {
      //获取所有权限
      menus(row.id).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.treeData = [];
          this.checked = [];
          this.treeData = res.data.data.childList;
          for (let i in this.treeData) {
            if (this.treeData[i].isSelected === 1) {
              this.checked.push(this.treeData[i].id);
              for (let j in this.treeData[i].childList) {
                if (this.treeData[i].childList[j].isSelected === 1) {
                  this.checked.push(this.treeData[i].childList[j].id);
                  for (let k in this.treeData[i].childList[j].childList) {
                    if (
                      this.treeData[i].childList[j].childList[k].isSelected ===
                      1
                    ) {
                      this.checked.push(
                        this.treeData[i].childList[j].childList[k].id
                      );
                    }
                  }
                }
              }
            }
          }
          this.$nextTick(() => {
            const nodes = [];
            this.checked.forEach((item) => {
              const node = this.$refs.menu.getNode(item);
              if (node.isLeaf) {
                nodes.push(item);
              }
            });
            this.$refs.menu.setCheckedKeys(nodes, true);
          });
          this.permissionVisible = true;
          // });
        }
      });
    },
    //新增/编辑角色点击
    roleControl(type, row) {
      if (type === "add") {
        this.roleTitle = "新增角色";
      } else {
        this.roleTitle = "编辑角色";
        this.ruleForm.roleName = row.roleName;
        this.ruleForm.roleDesc = row.roleDesc;
        this.ruleForm.id = row.id;
      }
      this.roleVisible = true;
    },
    //新增/编辑角色弹窗右上角关闭
    roleHandleClose(done) {
      this.clear();
      done();
    },
    //权限配置窗口右上角关闭
    permissionHandleClose(done) {
      this.treeData = [];
      this.menuIds = [];
      done();
    },
    //清空新增/编辑角色弹窗数据
    clear() {
      setTimeout(() => {
        this.$refs["ruleForm"].clearValidate();
      }, 100);
      this.ruleForm.roleName = "";
      this.ruleForm.roleDesc = "";
      this.ruleForm.id = "";
    },
    //新增/编辑角色弹窗取消
    roleCancel() {
      this.clear();
      this.roleVisible = false;
    },
    //新增/编辑角色弹窗确定
    roleSure(formName) {
      //节流
      //没有创建上来就为假，偷懒了不想每个都创建
      if (this.canRun) {
        return;
      }
      //变成真下一次点就会return
      this.canRun = true;
      //1秒钟后变成假
      setTimeout(() => {
        this.canRun = false;
      }, 4000);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.roleTitle === "新增角色") {
            save({ ...this.ruleForm }).then((res) => {
              if (res.data.msg != "success") {
                this.$message.error(res.data.msg);
              } else {
                this.$message({
                  message: "新增成功!",
                  type: "success",
                });
                this.loading = true;
                this.getTableData();
                this.clear();
                this.roleVisible = false;
              }
            });
          } else {
            let data = { ...this.ruleForm };
            update(data).then((res) => {
              if (res.data.msg != "success") {
                this.$message.error(res.data.msg);
              } else {
                this.$message({
                  message: "编辑成功!",
                  type: "success",
                });
                this.loading = true;
                this.getTableData();
                this.clear();
                this.roleVisible = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //权限配置取消
    permissionCancel() {
      //置空树形数据
      this.treeData = [];
      //置空当前树形选择
      this.checked = [];
      this.menuIds = [];
      //关闭显示权限框
      this.permissionVisible = false;
    },
    //权限配置确认
    permissionSure() {
      let serviceIds = [];
      // 得到已选中的 key 值
      this.$refs.menu.getCheckedKeys().forEach(function (data, index) {
        serviceIds.push(data);
      });
      this.$refs.menu.getHalfCheckedKeys().forEach(function (data, index) {
        serviceIds.push(data);
      });
      build({
        roleId: this.ruleForm.id,
        menuIds: serviceIds,
      }).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.treeData = [];
          this.menuIds = [];
          this.permissionVisible = false;
          this.$message({
            type: "success",
            message: "权限配置成功!",
          });
        }
      });
    },
    //删除角色
    roleDelete(index, row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delRole({ id: row.id }).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loading = true;
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      this.loading = true;
      this.getTableData();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      this.loading = true;
      this.getTableData();
    },
  },
};
</script>

<style scoped>
/*搜素区域距离底部距离*/
.el-row {
  margin-bottom: 20px;

  & :last-child {
    margin-bottom: 0;
  }
}

/*设置form表单子元素宽度*/
.content-wrapper >>> .el-form--inline .el-form-item__content {
  width: 75% !important;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}

/*表格class*/
.roleTable {
  /*height: 70%;*/
}
</style>
