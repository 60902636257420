<template>
  <div class="content-wrapper" v-cloak>
    <div class="flex-between top">
      <div class="flex-evenly box content">
        <div class="titleBg1">
          <img
            src="../assets/image/homePage/warehousing.png"
            width="50"
            style="position: relative; top: 19px; left: 19px"
          />
        </div>
        <div>
          <div class="smallTitTop">入库设备</div>
          <div class="smallTitBot">{{ equNumData.allEquNum }}</div>
        </div>
      </div>
      <div class="flex-evenly box content">
        <div class="titleBg2">
          <img
            src="../assets/image/homePage/activation.png"
            width="50"
            style="position: relative; top: 19px; left: 19px"
          />
        </div>
        <div>
          <div class="smallTitTop">激活设备</div>
          <div class="smallTitBot">{{ equNumData.activateNum }}</div>
        </div>
      </div>
      <div class="flex-evenly box content">
        <div class="titleBg3">
          <img
            src="../assets/image/homePage/normal.png"
            width="50"
            style="position: relative; top: 19px; left: 19px"
          />
        </div>
        <div>
          <div class="smallTitTop">正常设备</div>
          <div class="smallTitBot">{{ equNumData.noExpireNum }}</div>
        </div>
      </div>
      <div class="flex-evenly box content">
        <div class="titleBg4">
          <img
            src="../assets/image/homePage/on-line.png"
            width="50"
            style="position: relative; top: 19px; left: 19px"
          />
        </div>
        <div>
          <div class="smallTitTop">在线设备</div>
          <div class="smallTitBot">{{ equNumData.onlineNum }}</div>
        </div>
      </div>
      <div class="flex-evenly box content">
        <div class="titleBg5">
          <img
            src="../assets/image/homePage/user.png"
            width="50"
            style="position: relative; top: 19px; left: 19px"
          />
        </div>
        <div>
          <div class="smallTitTop">注册用户</div>
          <div class="smallTitBot">{{ equNumData.userNum }}</div>
        </div>
      </div>
    </div>
    <div class="flex-between middle">
      <div class="box" style="width: 815px; height: 400px; overflow: hidden">
        <div class="middleTit">新增用户&设备统计</div>
        <el-divider></el-divider>
        <div class="flex-around" style="margin-top: 12px">
          <div class="middleTextWidthL">
            <div>本月激活</div>
            <div>
              {{ userCostData.mouthActiveNum
              }}<span class="middleConTit">台</span>
            </div>
          </div>
          <div class="middleTextWidthL">
            <div>本月注册</div>
            <div>
              {{ userCostData.mouthLoginNum
              }}<span class="middleConTit">人</span>
            </div>
          </div>
          <div class="middleTextWidthL">
            <div>月均激活</div>
            <div>
              {{ userCostData.activeMouthAge
              }}<span class="middleConTit">台</span>
            </div>
          </div>
          <div class="middleTextWidthL">
            <div>月均注册</div>
            <div>
              {{ userCostData.loginMouthAge
              }}<span class="middleConTit">人</span>
            </div>
          </div>
        </div>
        <div id="middleL"></div>
      </div>
      <div class="box" style="width: 815px; height: 400px; overflow: hidden">
        <div class="middleTit">续费统计</div>
        <el-divider></el-divider>
        <div class="flex-evenly" style="margin-top: 12px">
          <div class="middleTextWidthR">
            <div>续费总额</div>
            <div>
              ¥{{ userCostData.allCost }}
              <span class="middleConTit">
                月均 ¥ {{ (userCostData.allCost / 12).toFixed(2) }}</span
              >
            </div>
          </div>
          <div class="middleTextWidthR">
            <div>续费总次</div>
            <div>
              {{ userCostData.allCostNum }}次
              <span class="middleConTit">
                月均 {{ (userCostData.allCostNum / 12).toFixed(2) }}</span
              >
            </div>
          </div>
          <div class="middleTextWidthR">
            <div>本月续费</div>
            <div>
              ¥{{ userCostData.mouthCost }}
              <span class="middleConTit"
                >{{ userCostData.mouthCost / 98 }} 次</span
              >
            </div>
          </div>
        </div>
        <div id="middleR"></div>
      </div>
    </div>
    <div class="flex-between bottom">
      <div class="box" style="width: 815px; height: 100%; overflow: hidden">
        <div class="middleTit">设备统计</div>
        <el-divider></el-divider>
        <div
          class="flex-between"
          style="width: 100%; height: 100%; margin-top: -70px"
        >
          <div id="bottomLOne"></div>
          <div id="bottomLTwo"></div>
          <div id="bottomLThree"></div>
          <div id="bottomLFour"></div>
        </div>
      </div>
      <div class="box" style="width: 264px; height: 100%; overflow: hidden">
        <div class="middleTit">设备概览</div>
        <el-divider></el-divider>
        <div id="bottomMOne"></div>
      </div>
      <div class="box" style="width: 529px; height: 100%; overflow: hidden">
        <div class="middleTit flex-between">
          报警统计
          <span
            class="flex-around"
            style="
              width: 40%;
              font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.647058823529412);
            "
          >
            <div
              :class="{ btn: true, active: type === '1' }"
              @click="getAlarm('1')"
            >
              本日
            </div>
            <div
              :class="{ btn: true, active: type === '2' }"
              @click="getAlarm('2')"
            >
              本周
            </div>
            <div
              :class="{ btn: true, active: type === '3' }"
              @click="getAlarm('3')"
            >
              本月
            </div>
            <div
              :class="{ btn: true, active: type === '4' }"
              @click="getAlarm('4')"
            >
              本年
            </div>
          </span>
        </div>
        <el-divider></el-divider>
        <div id="bottomROne" style="margin-left: -380px"></div>
      </div>
    </div>
  </div>
</template>

<script>
let echartsData = [
  {
    day: "09-08",
    count: 2,
  },
  {
    day: "09-07",
    count: 3,
  },
  {
    day: "09-06",
    count: 3,
  },
  {
    day: "09-05",
    count: 3,
  },
  {
    day: "09-04",
    count: 3,
  },
  {
    day: "09-03",
    count: 3,
  },
  {
    day: "09-02",
    count: 3,
  },
];
let data = {
  one: "3",
  two: "4",
};
import { equ, alarm, userCost } from "../tools/common/homePage";
export default {
  name: "HomePage",
  data() {
    return {
      equNumData: [],
      alarmNumData: [],
      userCostData: [],
      type: "1",
    };
  },
  created() {
    this.$store.state.nowName = "首页";
    this.getEqu();
    this.getAlarm(this.type);
    this.getUserCost();
    this.$nextTick(() => {});
  },
  methods: {
    getEqu() {
      equ().then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.equNumData = res.data.data;
          this.$chart.pie("bottomLOne", this.equNumData, "入库总数");
          this.$chart.pie("bottomLTwo", this.equNumData, "激活总数");
          this.$chart.pie("bottomLThree", this.equNumData, "正常总数");
          this.$chart.pie("bottomLFour", this.equNumData, "在线总数");
          this.$chart.funnel("bottomMOne", this.equNumData);
        }
      });
    },
    getAlarm(type) {
      this.type = type;
      alarm(type).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.alarmNumData = res.data.data;
          if (this.alarmNumData.length !== 0) {
            this.$chart.pieP("bottomROne", this.alarmNumData);
          }
        }
      });
    },
    getUserCost() {
      userCost().then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.userCostData = res.data.data;
          this.$chart.line("middleL", this.userCostData.list);
          this.$chart.lineBar("middleR", this.userCostData.list);
        }
      });
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 0;
  background: #f0f2f5;
}

/* 白盒子 */
.box {
  background: #ffffff;
  border-radius: 5px;
}

/* 上 */
.top {
  width: 100%;
  height: 15%;
  /* height: 13vh; */
}
/* 上内容 */
.top .content {
  width: 300px;
  height: 100%;
}
/* 上内容图像 */
.titleBg1 {
  width: 86px;
  height: 86px;
  background: url("../assets/image/homePage/blue.svg") center center no-repeat;
}
.titleBg2 {
  width: 86px;
  height: 86px;
  background: url("../assets/image/homePage/green.svg") center center no-repeat;
}
.titleBg3 {
  width: 86px;
  height: 86px;
  background: url("../assets/image/homePage/purple.svg") center center no-repeat;
}
.titleBg4 {
  width: 86px;
  height: 86px;
  background: url("../assets/image/homePage/Lgreen.svg") center center no-repeat;
}
.titleBg5 {
  width: 86px;
  height: 86px;
  background: url("../assets/image/homePage/pink.svg") center center no-repeat;
}
/* 上内容标题上 */
.smallTitTop {
  color: rgba(0, 0, 0, 0.427450980392157);
  font-size: 20px;
}
/* 上内容标题下 */
.smallTitBot {
  font-size: 30px;
}
/* 中 */
.middle {
  width: 100%;
  /* height: 45vh; */
  height: 52%;
}
/* 中间标题 */
.middleTit {
  font-size: 16px;
  font-style: normal;
  font-weight: bolder;
  margin-left: 20px;
  margin-top: 20px;
}
/* 中间内容标题 */
.middleConTit {
  color: rgba(0, 0, 0, 0.427450980392157);
  font-size: 15px;
}
/* 中间左小标题大小 */
.middleTextWidthL {
  width: 25%;
  text-align: center;
}
/* 中间左小标题第一个div样式 */
.middleTextWidthL div:nth-child(1) {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.427450980392157);
}
/* 中间左小标题第二个div样式 */
.middleTextWidthL div:nth-child(2) {
  font-size: 22px;
  margin-top: 3px;
}
/* 中间右小标题大小 */
.middleTextWidthR {
  width: 30%;
}
/* 中间右小标题第一个div样式 */
.middleTextWidthR div:nth-child(1) {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.427450980392157);
}
/* 中间右小标题第二个div样式 */
.middleTextWidthR div:nth-child(2) {
  font-size: 22px;
  margin-top: 3px;
}
/* 中左 */
#middleL {
  width: 100%;
  height: 72%;
}
/* 中右 */
#middleR {
  width: 100%;
  height: 72%;
}
/* 下 */
.bottom {
  width: 100%;
  /* height: 30vh; */
  height: 33%;
}
/* 下左1 */
#bottomLOne {
  width: 25%;
  height: 75%;
} /* 下左2 */
#bottomLTwo {
  width: 25%;
  height: 75%;
} /* 下左3 */
#bottomLThree {
  width: 25%;
  height: 75%;
} /* 下左4 */
#bottomLFour {
  width: 25%;
  height: 75%;
} /* 下中 */
#bottomMOne {
  width: 100%;
  height: 90%;
  margin-top: -20px;
} /* 下右 */
#bottomROne {
  width: 180%;
  height: 90%;
  margin-top: -40px;
  /* margin-left: -220px; */
}
.btn {
  width: 20%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.active {
  background: #108ee9;
  color: #fff;
}
.btn:hover {
  background: #108ee9;
  color: #fff;
}
</style>
