<template>
  <div id="wrapper">
    <img
      class="bg"
      src="../assets/image/login/bg.png"
      alt=""
      width="100%"
      height="75%"
    />
    <div class="flex-between top">
      <div class="flex-around logo">
        <img
          src="../assets/image/login/logo.png"
          alt=""
          width="154px;"
          height="70px;"
        />
        <div
          style="
            margin-left: 20px;
            font-size: 35px;
            color: rgba(0, 0, 0, 0.698039215686274);
          "
        >
          设备远程在线监控系统
        </div>
      </div>
    </div>
    <div class="bottom">
      <div style="font-size: 22px">常州市武进区洛阳博奥电子电器厂</div>
      <a
        style="font-size: 18px; color: #958673"
        href="https://beian.miit.gov.cn/#/"
        >苏ICP备2022042375号</a
      >
    </div>
    <el-card class="box-card">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="0"
        class="demo-ruleForm"
      >
        <el-form-item label="" prop="account" style="text-align: center">
          <div
            style="
              font-size: 20px;
              font-weight: bolder;
              letter-spacing: 2px;
              color: #3d3d3d;
            "
          >
            登 录
          </div>
        </el-form-item>
        <el-form-item label="" prop="account">
          <el-input
            v-model="ruleForm.account"
            prefix-icon="el-icon-user"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            prefix-icon="el-icon-lock"
            placeholder="请输入密码"
            @keyup.enter.native="accountSubmitForm('ruleForm')"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="flex-between">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <div style="color: #1890ff">忘记密码</div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="submit"
            style="background-color: #1890ff; letter-spacing: 4px"
            @click="accountSubmitForm('ruleForm')"
          >
            {{ loginText }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { webLogin } from "@/tools/common/login";
export default {
  name: "login",
  data() {
    return {
      //控制获取验证码按钮点击
      disabled: false,
      //记住密码
      checked: false,
      //账号验证
      ruleForm: {
        account: "",
        password: "",
      },
      //账号验证规则
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      //登录文字
      loginText: "登录",
    };
  },
  created() {
    //从loaclstroage 里获取账号和密码，并判断是否选中
    this.ruleForm.account = JSON.parse(window.localStorage.getItem("account"));
    this.ruleForm.password = JSON.parse(
      window.localStorage.getItem("password")
    );
    this.checked = window.localStorage.getItem("checked") === "true";
  },
  methods: {
    //加密 base64
    encode(str) {
      if (str === undefined || str === "" || str === null) {
        return str;
      }
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    //账号登录提交
    accountSubmitForm(formName) {
      //验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            userName: this.ruleForm.account,
            password: this.encode(this.ruleForm.password),
            userType: 2,
          };
          webLogin(data).then((res) => {
            this.loginText = "登录中...";
            if (res.data.code === 20000) {
              //保存账号密码
              if (this.checked) {
                window.localStorage.setItem(
                  "account",
                  JSON.stringify(this.ruleForm.account)
                );
                window.localStorage.setItem(
                  "password",
                  JSON.stringify(this.ruleForm.password)
                );
                window.localStorage.setItem("checked", "true");
              } else {
                window.localStorage.removeItem("account");
                window.localStorage.removeItem("password");
                window.localStorage.setItem("checked", "false");
              }
              //用户信息赋值
              this.$store.state.userInfo = res.data.data;
              //跳转登录
              this.$router.push("/homePage");
            } else {
              this.$message.error(res.data.msg);
              this.loginText = "登录";
            }
          });
        } else {
          return false;
        }
      });
    },
    //tabs 标签切换
    handleClick(tab, event) {
      // console.log(tab, event);
    },
  },
};
</script>

<style scoped>
#wrapper {
  width: 100%;
  height: 100%;
}

.box-card {
  position: absolute;
  right: 10%;
  top: 31%;
  width: 340px;
  border-radius: 10px;
  box-sizing: border-box;
}

#wrapper >>> .el-tabs__item {
  width: 100%;
  text-align: center;
}

#wrapper >>> .el-tabs__nav {
  width: 179px;
  text-align: center;
}

.submit {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
}

#wrapper >>> .el-tabs__content {
  padding-top: 30px;
}

.code {
  width: 38%;
}

/*手机号登录按钮样式*/
.phoneSubmit {
  width: 100%;
  margin-top: 62px;
}
.bg {
  margin-top: 12vh;
}
/*左上角公司logo*/
.logo {
  /* width: 550px; */
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
  justify-content: flex-start;
  font-family: "Microsoft YaHei";
}

/*服务热线*/
.phone {
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 35px;
  font-family: "Microsoft YaHei";
}
.top {
  position: absolute;
  top: 1vw;
  padding: 0 8%;
  width: 100vw;
  box-sizing: border-box;
  align-items: center;
}
/*底部*/
.bottom {
  font-family: "Microsoft YaHei";
  position: absolute;
  font-size: 1.2vw;
  bottom: 3%;
  width: 100%;
  text-align: center;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1890ff;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
</style>
