import request from "../request"
//获取表格数据
function getPageList(data) {
    return request({
        method: 'post',
        url: '/alarm/cost/page',
        data
    })

}

export {
    getPageList
}

