import request from "../request"
//查询设备数据
function getPage(data) {
    return request({
        method: 'post',
        url: '/equipmentTest/page',
        data
    })
}
//删除
function removeById(data) {
    return request({
        method: 'post',
        url: '/equipmentTest/removeById',
        data
    })
}
//入库
function putInStorage(data) {
    return request({
        method: 'post',
        url: '/equipmentTest/putInStorage',
        data
    })
}
//开始测试
function testOn(data) {
    return request({
        method: 'post',
        url: '/equipmentTest/testOn',
        data
    })
}
//关闭测试
function testOff(data) {
    return request({
        method: 'post',
        url: '/equipmentTest/testOff',
        data
    })
}
//查看状态
function status(data) {
    return request({
        method: 'post',
        url: '/equipmentTest/status',
        data
    })
}
//测试
function test(data) {
    return request({
        method: 'post',
        url: '/wx/equipment/on-off',
        data
    })
}
export {
    getPage, removeById, putInStorage, testOn, testOff, status, test
}

