<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <el-form
      :inline="true"
      :model="searchArea"
      class="formInline"
      label-width="90px"
    >
      <el-row :gutter="8">
        <el-col :span="6">
          <el-form-item label="设备编号：">
            <el-input
              v-model="searchArea.sn"
              size="small"
              placeholder="请输入设备编号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备状态：">
            <el-select
              v-model="searchArea.status"
              size="small"
              placeholder="请输入设备状态"
              clearable
            >
              <el-option label="未激活" value="0"></el-option>
              <el-option label="在线" value="1"></el-option>
              <el-option label="离线" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="监控点名称：">
            <el-input
              v-model="searchArea.monitorName"
              size="small"
              placeholder="请输入监控点名称"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-collapse-transition>
        <div v-if="show">
          <el-row :gutter="80">
            <el-col :span="6">
              <el-form-item label="SIM卡号：">
                <el-input
                  v-model="searchArea.sim"
                  size="small"
                  placeholder="请输入SIM卡号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="入库时间：">
                <el-date-picker
                  size="small"
                  v-model="searchArea.createTime"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="80">
            <el-col :span="6">
              <el-form-item label="能否续费：">
                <el-select
                  v-model="searchArea.isRenewal"
                  size="small"
                  placeholder="请选择能否续费"
                  clearable
                >
                  <el-option
                    v-for="item in isRenewalData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="激活时间：">
                <el-date-picker
                  size="small"
                  v-model="searchArea.activateTime"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-collapse-transition>
      <el-row :gutter="80">
        <el-col :span="6">
          <el-form-item label="是否到期：">
            <el-select
              v-model="searchArea.isExpire"
              size="small"
              placeholder="请选择是否到期"
              clearable
            >
              <el-option
                v-for="item in isExpireData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="到期时间：">
            <el-date-picker
              size="small"
              v-model="searchArea.expirationTime"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="width: 100% !important">
            <el-button type="text" size="small" @click="show = !show"
              >{{ show ? "收起搜索" : "展开搜索"
              }}<i
                :class="show ? 'el-icon-arrow-up ' : 'el-icon-arrow-down'"
              ></i
            ></el-button>
            <el-button
              style="margin-left: 30px"
              type="primary"
              size="small"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '列表查询'
                ) != -1
              "
              @click="onSubmit"
              >查询</el-button
            >
            <el-button
              size="small"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '列表查询'
                ) != -1
              "
              @click="reset"
              >重置</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--        表格区域-->
    <div class="table roleTable">
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="sn"
          label="设备编号"
          align="center"
          min-width="100"
        >
          <template slot-scope="scope">
            <a @click="goDetail(scope.row)">{{ scope.row.sn }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="monitorName"
          label="监控点名称"
          align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="equipmentType"
          label="型号"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.equipmentType === 1">4-LOT</div>
            <div v-else-if="scope.row.equipmentType === 2">Q3</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 1">
              <span style="color: #00a854">● </span>在线
            </div>
            <div v-else-if="scope.row.status === 2">
              <span style="color: #f04134">● </span>离线
            </div>
            <div v-else-if="scope.row.status === 0">
              <span style="color: #f39c12">● </span>未激活
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="isExpire"
          label="是否到期"
          align="center"
          min-width="50"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isExpire === 1">已到期</div>
            <div v-else-if="scope.row.isExpire === 0">未到期</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="isRenewal"
          label="能否续费"
          align="center"
          min-width="50"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isRenewal === 1">能</div>
            <div v-else-if="scope.row.isRenewal === 0">否</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="入库时间"
          align="center"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="activateTime"
          label="激活时间"
          align="center"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="expirationTime"
          label="到期时间"
          align="center"
          min-width="120"
        >
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '编辑'
                ) != -1
              "
              @click="equipControl('edit', scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="primary"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '实时数据'
                ) != -1
              "
              @click="openReal(scope.row)"
              >实时数据
            </el-button>
            <el-button
              size="mini"
              type="primary"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '历史数据'
                ) != -1
              "
              @click="goHistory(scope.row)"
              >历史数据
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--        分页区域-->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchArea.current"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="searchArea.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="equipTitle"
      :visible.sync="equipVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="equipHandleClose"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="备注：" prop="note">
          <el-input size="small" v-model="ruleForm.note"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="equipCancel('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="equipSure('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      custom-class="realData"
      title="实时数据"
      :visible.sync="realVisible"
      :close-on-click-modal="false"
      width="400px"
      @close="realVisible = false"
    >
      <div style="margin: 10px 15px" class="flex-between">
        <span>最近一次采集时间</span>
        <span v-if="realData.inTime !== null">{{ realData.inTime }}</span>
        <span v-else>-</span>
      </div>
      <div
        class="flex-between bg"
        style="flex-wrap: wrap; height: 350px"
        v-if="type === 1"
      >
        <div
          style="
            margin-left: 10px;
            width: 20%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-around;
          "
        >
          <div class="height">
            <div class="number" v-if="realData.g03 !== null">
              {{ realData.g03 }} A
            </div>
            <div v-else>- A</div>
            <div class="word">A相电流</div>
          </div>
          <div class="height">
            <div class="number" v-if="realData.g04 !== null">
              {{ realData.g04 }} A
            </div>
            <div v-else>- A</div>
            <div class="word">B相电流</div>
          </div>
          <div class="height">
            <div class="number" v-if="realData.g05 !== null">
              {{ realData.g05 }} A
            </div>
            <div v-else>- A</div>
            <div class="word">C相电流</div>
          </div>
        </div>
        <div
          style="
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
          "
        >
          <div style="position: relative; display: inline-block">
            <img
              src="../../assets/cl.png"
              style="width: 180px; height: 186px"
            />
            <div class="text-overlay">
              <div class="bigNum" v-if="realData.g01 !== ''">
                {{ realData.g01 }}℃
              </div>
              <div class="bigNum" v-else>- ℃</div>
              <div class="bigWord">冷库温度</div>
            </div>
          </div>
          <div class="flex-column" style="width: 100%; height: 51px">
            <div style="font-size: 17px">
              <div class="number" v-if="realData">{{ realData.g02 }} ℃</div>
              <div class="number" v-else>-</div>
              <div class="word">化霜温度</div>
            </div>
          </div>
        </div>
        <div
          style="
            margin-right: 10px;
            width: 20%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-around;
          "
        >
          <div class="height">
            <div class="number" v-if="realData">
              <span v-if="realData.g08 === 1">开</span>
              <span v-else-if="realData.g08 === 0">关</span>
              <span v-else>-</span>
            </div>
            <div class="word">风机</div>
          </div>
          <div class="height">
            <div class="number" v-if="realData">
              <span v-if="realData.g07 === 1">开</span
              ><span v-else-if="realData.g07 === 0">关</span>
              <span v-else>-</span>
            </div>
            <div class="word">化霜</div>
          </div>
          <div class="height">
            <div class="number" v-if="realData">
              <span v-if="realData.g06 === 1">开</span>
              <span v-else-if="realData.g06 === 0">关</span>
              <span v-else>-</span>
            </div>
            <div class="word">压缩机</div>
          </div>
        </div>
      </div>
      <div
        class="flex-between bg"
        style="flex-wrap: wrap; height: 300px; padding: 30px 0"
        v-else-if="type === 2"
      >
        <div
          style="
            margin-left: 10px;
            width: 20%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
          "
        >
          <div class="height">
            <div class="number" v-if="realData.g03 !== null">
              {{ realData.g03 }} A
            </div>
            <div v-else>- A</div>
            <div class="word">A相电流</div>
          </div>
          <div class="height">
            <div class="number" v-if="realData.g04 !== null">
              {{ realData.g04 }} A
            </div>
            <div v-else>- A</div>
            <div class="word">B相电流</div>
          </div>
          <div class="height">
            <div class="number" v-if="realData.g05 !== null">
              {{ realData.g05 }} A
            </div>
            <div v-else>- A</div>
            <div class="word">C相电流</div>
          </div>
        </div>
        <div
          style="
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div
            style="position: relative; display: inline-block; margin-top: 20px"
          >
            <img
              src="../../assets/cl.png"
              style="width: 180px; height: 186px"
            />
            <div class="text-overlay">
              <div class="bigNum" v-if="realData.g01 !== ''">
                {{ realData.g01 }}℃
              </div>
              <div class="bigNum" v-else>- ℃</div>
              <div class="bigWord">冷库温度</div>
            </div>
          </div>
          <div class="flex-column" style="width: 100%; height: 51px">
            <div style="font-size: 17px">
              <div class="number" v-if="realData">{{ realData.g02 }} ℃</div>
              <div class="number" v-else>-</div>
              <div class="word">化霜温度</div>
            </div>
          </div>
        </div>
        <div
          style="
            margin-right: 10px;
            width: 20%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
          "
        >
          <div class="height">
            <div style="height: 22px" v-if="realData">
              <span v-if="realData.g06 === 1"
                ><img src="../../assets/img/clod-open.svg" style="height: 22px"
              /></span>
              <span v-else-if="realData.g06 === 0"
                ><img src="../../assets/img/clod-close.svg" style="height: 22px"
              /></span>
              <span v-else>-</span>
            </div>
            <div class="word">制冷</div>
          </div>
          <div class="height">
            <div style="height: 22px" v-if="realData">
              <span v-if="realData.g08 === 1"
                ><img src="../../assets/img/fen-open.svg" style="height: 22px"
              /></span>
              <span v-else-if="realData.g08 === 0"
                ><img src="../../assets/img/fen-close.svg" style="height: 22px"
              /></span>
              <span v-else>-</span>
            </div>
            <div class="word">风机</div>
          </div>
          <div class="height">
            <div style="height: 22px" v-if="realData">
              <span v-if="realData.g07 === 1"
                ><img
                  src="../../assets/img/defrost-open.svg"
                  style="height: 22px"
              /></span>
              <span v-else-if="realData.g07 === 0"
                ><img
                  src="../../assets/img/defrost-close.svg"
                  style="height: 22px"
              /></span>
              <span v-else>-</span>
            </div>
            <div class="word">化霜</div>
          </div>
          <div class="height">
            <div style="height: 22px" v-if="realData">
              <span v-if="realData.g09 === 1"
                ><img
                  src="../../assets/img/hot-open.svg"
                  style="height: 22px"/></span
              ><span v-else-if="realData.g09 === 0"
                ><img src="../../assets/img/hot-close.svg" style="height: 22px"
              /></span>
              <span v-else>-</span>
            </div>
            <div class="word">制热</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPage, realData, save } from "@/tools/equipment/equipManage";
import { timing } from "../../components/time";
export default {
  name: "equipManage",
  data() {
    return {
      //查询数据
      searchArea: {
        //sim
        sim: "",
        //设备编号
        sn: "",
        //设备状态
        status: "",
        //入库时间
        createTime: [],
        //激活时间
        activateTime: [],
        //到期时间
        expirationTime: [],
        //监控点名称
        monitorName: "",
        isRenewal: "",
        isExpire: "",
        //表格宽度
        size: 5,
        //当前多少页
        current: 1,
      },
      isExpireData: [
        {
          label: "已到期",
          value: 1,
        },
        {
          label: "未到期",
          value: 0,
        },
      ],
      isRenewalData: [
        {
          label: "能",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      //表格数据
      tableData: [],
      realData: [],
      //表格数据总数
      total: 0,
      //新增/编辑设备 弹窗title
      equipTitle: "",
      //新增/编辑设备 弹窗显示
      equipVisible: false,
      realVisible: false,
      //新增/编辑设备 弹窗内数据
      ruleForm: {
        id: "",
        note: "",
      },
      //表格loading
      loading: false,
      show: false,
      //
      type: "",
      //起止时间数据控制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      permissionsList: [],
    };
  },
  created() {
    this.$store.state.nowName = "设备管理";
    this.permissionsList = this.$store.state.permissionsList;
    this.getTableData();
    console.log(this.$store.state.permissionsList);
  },
  methods: {
    goDetail(row) {
      this.$store.state.equipInfo = row;
      this.$store.state.permissionsList = this.permissionsList;
      this.$router.push("../equipment/equipDetail");
    },
    goHistory(row) {
      this.$store.state.equipInfo = row;
      this.$router.push("../equipment/equipHistory");
    },
    openReal(row) {
      console.log("row", row);
      this.type = row.equipmentType;
      realData({
        id: row.id,
        sn: row.sn,
      }).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.realData = [];
          console.log(res.data.data.g03);
          if (res.data.data != null) {
            this.realData = res.data.data;
          } else {
            this.realData = [];
          }
        }
      });
      setTimeout(() => {
        this.realVisible = true;
      }, 100);
    },
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      this.getTableData();
    },
    //重置查询参数
    reset() {
      this.searchArea.sim = "";
      this.searchArea.sn = "";
      this.searchArea.status = "";
      this.searchArea.createTime = "";
      this.searchArea.activateTime = "";
      this.searchArea.expirationTime = "";
      this.searchArea.monitorName = "";
      this.searchArea.isRenewal = "";
      this.searchArea.isExpire = "";
    },
    //获取表格数据
    getTableData() {
      let data = { ...this.searchArea };
      if (data.expirationTime != null && data.expirationTime.length != 0) {
        let timeData = timing(data.expirationTime);
        data.expirationTimeStart = timeData[0];
        data.expirationTimeEnd = timeData[1];
      }
      if (data.activateTime != null && data.activateTime.length != 0) {
        let timeData = timing(data.activateTime);
        data.activateTimeStart = timeData[0];
        data.activateTimeEnd = timeData[1];
      }
      if (data.createTime != null && data.createTime.length != 0) {
        let timeData = timing(data.createTime);
        data.createTimeStart = timeData[0];
        data.createTimeEnd = timeData[1];
      }
      getPage(data).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.tableData = [];
          if (res.data.data != null) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    //新增/编辑设备点击
    equipControl(type, row) {
      this.equipTitle = "编辑设备";
      this.ruleForm.note = row.note;
      this.ruleForm.id = row.id;
      this.equipVisible = true;
    },
    //新增/编辑设备弹窗右上角关闭
    equipHandleClose(done) {
      this.clear();
      done();
    },
    //清空新增/编辑设备弹窗数据
    clear() {
      setTimeout(() => {
        this.$refs["ruleForm"].clearValidate();
      }, 100);
    },
    //新增/编辑设备弹窗取消
    equipCancel() {
      this.clear();
      this.equipVisible = false;
    },
    //新增/编辑设备弹窗确定
    equipSure(formName) {
      //节流
      //没有创建上来就为假，偷懒了不想每个都创建
      if (this.canRun) {
        return;
      }
      //变成真下一次点就会return
      this.canRun = true;
      //1秒钟后变成假
      setTimeout(() => {
        this.canRun = false;
      }, 4000);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          save({
            id: this.ruleForm.id,
            note: this.ruleForm.note,
          }).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.$message({
                message: "编辑成功!",
                type: "success",
              });
              this.loading = true;
              this.getTableData();
              this.clear();
              this.equipVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      this.loading = true;
      this.getTableData();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      this.loading = true;
      this.getTableData();
    },
  },
};
</script>

<style scoped>
/*搜素区域距离底部距离*/
.el-row {
  margin-bottom: 20px;
}

/*设置form表单子元素宽度*/
.content-wrapper >>> .el-form--inline .el-form-item__content {
  width: 75% !important;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}
>>> .el-dialog__body {
  padding: 0px !important;
  color: black;
}
.demo-ruleForm {
  padding: 30px 40px;
}
/*表格class*/
.roleTable {
  /*height: 70%;*/
}
.bg {
  background: url("../../assets/databg.png") no-repeat;
  background-size: 100% 100%;
}
.number {
  box-shadow: none;
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
}

.word {
  box-shadow: none;
  color: #555555;
  text-align: center;
}

.bigNum {
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 27px;
  color: #016cfc;
  margin-bottom: 10px;
}

.bigWord {
  box-shadow: none;
  font-size: 16px;
  text-align: center;
}

.height {
  height: 50px;
  width: 80px;
  font-size: 16px;
}

.yuan {
  background: url("../../assets/cl.png") no-repeat;
  background-size: 100% 100%;
  width: 180px;
  height: 186px;
}
.text-overlay {
  position: absolute;
  top: 42%;
  left: 48%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
