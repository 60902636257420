import request from "../request"
//账号密码登录
function webLogin(data) {
    return request({
        method: 'post',
        url: '/userMst/webLogin',
        data
    })

}
//退出
function logOut(data) {
    return request({
        method: 'post',
        url: '/userMst/webLogout',
        data
    })

}
//获取角色菜单
function menus(data) {
    return request({
        method: 'post',
        url: '/role/menus',
        data
    })

}

export {
    webLogin, menus, logOut
}

