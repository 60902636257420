import request from "../request"
//获取表格数据
function getPageList(data) {
    return request({
        method: 'post',
        url: '/role/page',
        data
    })

}
//新增角色
function save(data) {
    return request({
        method: 'post',
        url: '/role/save',
        data
    })

}
//编辑角色
function update(data) {
    return request({
        method: 'post',
        url: '/role/update/',
        data
    })

}
//删除角色
function delRole(params) {
    return request({
        method: 'post',
        url: '/role/delete',
        params: params
    })

}
//获取角色菜单
function menus(data) {
    return request({
        method: 'post',
        url: '/role/menus',
        data
    })

}
//绑定角色菜单
function build(data) {
    return request({
        method: 'post',
        url: '/role/build',
        data
    })

}
export {
    getPageList, save, update, delRole, menus, build
}

