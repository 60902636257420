import request from "../request"
//获取表格数据
function getPageList(data) {
    return request({
        method: 'post',
        url: '/userMst/page',
        data
    })

}
//获取表格数据
function getMonitorData(data) {
    return request({
        method: 'post',
        url: '/equipmentRole/listByUserId',
        data
    })

}
//新增用户 
function add(data) {
    return request({
        method: 'post',
        url: '/userMst/add',
        data
    })

}
//编辑用户
function update(data) {
    return request({
        method: 'post',
        url: '/userMst/wx/update',
        data
    })

}
//编辑用户
function updateP(data) {
    return request({
        method: 'post',
        url: '/userMst/update',
        data
    })

}
//删除角色
function del(data) {
    return request({
        method: 'post',
        url: '/userMst/del',
        data
    })

}
//修改密码
function resetPwd(data) {
    return request({
        method: 'post',
        url: '/userMst/resetPwd',
        data
    })

}
export {
    getMonitorData, getPageList, update, del, add, resetPwd, updateP
}

