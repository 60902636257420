import 'codemirror/lib/codemirror.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment';
import Vue from 'vue';
import Print from 'vue-print-nb';
import myCharts from '../src/components/myEcharts'; //引入eCharts 文件
import App from './App.vue';
import router from './router';
import store from './store';
Vue.use(Print)
Vue.use(myCharts);
//代码编辑器
let VueCodemirror = require('vue-codemirror')
Vue.use(VueCodemirror)
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$moment = moment;

//图片地址
// Vue.prototype.$url = "http://192.168.8.5/static";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
