<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <el-form
      :inline="true"
      :model="searchArea"
      class="formInline"
      label-width="80px"
    >
      <el-row :gutter="80">
        <el-col :span="5">
          <el-form-item label="手机号码：">
            <el-input
              v-model="searchArea.phone"
              size="small"
              placeholder="请输入手机号码"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="注册时间：">
            <el-date-picker
              size="small"
              v-model="searchArea.time"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmit"
              >查询</el-button
            >
            <el-button size="small" @click="reset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--        表格区域-->
    <div class="table">
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        :data="tableData"
        v-loading="loading"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="nikeName" label="姓名" min-width="150">
        </el-table-column>
        <el-table-column prop="phoneNo" label="手机号码" min-width="150">
        </el-table-column>
        <el-table-column prop="createTime" label="注册时间" min-width="150">
        </el-table-column>
        <el-table-column prop="note" label="备注" min-width="150">
        </el-table-column>
        <el-table-column label="操作" width="280" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '编辑用户'
                ) != -1
              "
              @click="userControl(scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '删除用户'
                ) != -1
              "
              @click="userDelete(scope.$index, scope.row)"
              >删除
            </el-button>
            <el-button
              size="mini"
              type="primary"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '查看监控点'
                ) != -1
              "
              @click="monitorControl(scope.row)"
              >查看监控点
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--        分页区域-->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchArea.current"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="searchArea.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="userTitle"
      :visible.sync="userVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="userHandleClose"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="ruleForm.note"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userCancel('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="userSure('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="监控点数据"
      :visible.sync="monitorVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="monitorHandleClose"
    >
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        :data="monitorData"
        v-loading="loading"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column prop="monitorName" label="监控点名称" min-width="150">
        </el-table-column>
        <el-table-column prop="role" label="权限" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.role === 0">待分配</span>
            <span v-else-if="scope.row.role === 1">管理员</span>
            <span v-else-if="scope.row.role === 2">助理</span>
            <span v-else-if="scope.row.role === 3">员工</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPageList,
  update,
  del,
  add,
  getMonitorData,
} from "@/tools/userCenter/userControl";
import { timing } from "../../components/time";
export default {
  name: "userControl",
  data() {
    return {
      //查询数据
      searchArea: {
        phone: "",
        time: "",
        userType: 1,
        //表格宽度
        size: 5,
        //当前多少页
        current: 1,
      },
      //起止时间数据控制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      //表格数据
      tableData: [],
      //监控点数据
      monitorData: [],
      //表格数据总数
      total: 0,
      //新增/编辑用户 弹窗title
      userTitle: "",
      //新增/编辑用户 弹窗显示
      userVisible: false,
      monitorVisible: false,
      //新增/编辑用户 弹窗内数据
      ruleForm: {
        userType: 1,
        note: "",
        id: "",
      },
      //表格loading
      loading: false,
      permissionsList: [],
    };
  },
  created() {
    this.$store.state.nowName = "用户管理";
    this.permissionsList = this.$store.state.permissionsList;

    this.getTableData();
    // this.getRoleData();
  },
  methods: {
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      this.getTableData();
    },
    //重置查询参数
    reset() {
      this.searchArea.time = "";
      this.searchArea.phone = "";
    },
    //获取表格数据
    getTableData() {
      let data = { ...this.searchArea };
      if (data.time != null && data.time.length != 0) {
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      }
      getPageList(data).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.tableData = [];
          if (res.data.data != null) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    //获取表格数据
    getMonitorData(id) {
      getMonitorData({
        userId: id,
      }).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.monitorData = [];
          if (res.data.data != null) {
            this.monitorData = res.data.data;
            // this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.monitorData = [];
            // this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      this.loading = true;
      this.getTableData();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      this.loading = true;
      this.getTableData();
    },
    //新增/编辑用户弹窗右上角关闭
    userHandleClose(done) {
      this.clear();
      done();
    },
    //监控点数据弹窗右上角关闭
    monitorHandleClose(done) {
      this.monitorData = [];
      done();
    },
    //监控点点击
    monitorControl(row) {
      this.getMonitorData(row.id);
      this.monitorVisible = true;
    },
    //编辑用户点击
    userControl(row) {
      this.userTitle = "编辑用户";
      this.ruleForm.note = row.note;
      this.ruleForm.id = row.id;
      this.ruleForm.userType = row.userType;
      this.userVisible = true;
    },
    //清空新增/编辑用户弹窗数据
    clear() {
      //异步操作
      setTimeout(() => {
        this.$refs["ruleForm"].clearValidate();
      }, 100);
      this.ruleForm.note = "";
    },
    //新增/编辑用户弹窗取消
    userCancel() {
      this.clear();
      this.userVisible = false;
    },
    //新增/编辑用户弹窗确定
    userSure(formName) {
      //节流
      //没有创建上来就为假，偷懒了不想每个都创建
      if (this.canRun) {
        return;
      }
      //变成真下一次点就会return
      this.canRun = true;
      //1秒钟后变成假
      setTimeout(() => {
        this.canRun = false;
      }, 4000);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = { ...this.ruleForm };
          if (this.userTitle === "新增用户") {
            add(data).then((res) => {
              if (res.data.msg != "success") {
                this.$message.error(res.data.msg);
              } else {
                this.$message({
                  message: "新增成功!",
                  type: "success",
                });
                this.loading = true;
                this.getTableData();
                this.clear();
                this.userVisible = false;
              }
            });
          } else {
            update({
              id: this.ruleForm.id,
              note: this.ruleForm.note,
            }).then((res) => {
              if (res.data.msg != "success") {
                this.$message.error(res.data.msg);
              } else {
                this.$message({
                  message: "编辑成功!",
                  type: "success",
                });
                this.loading = true;
                this.getTableData();
                this.clear();
                this.userVisible = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //删除用户
    userDelete(index, row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({
            id: row.id,
          }).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loading = true;
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
/*搜素区域距离底部距离*/
.el-row {
  margin-bottom: 20px;

  & :last-child {
    margin-bottom: 0;
  }
}

/*设置form表单子元素宽度*/
.content-wrapper >>> .el-form--inline .el-form-item__content {
  width: 75% !important;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}
</style>
