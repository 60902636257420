import moment from 'moment';
export const timing = (timeData) => {
    let startTimes = timeData[0]; // 开始时间
    let startStamp = Date.parse(startTimes); // 开始时间时间戳
    let endTimes = timeData[1]; // 结束时间
    let endStamp = Date.parse(endTimes); // 结束时间时间戳
    // 获取零点时刻
    function formatTime(timestamp) {
        let time = new Date(timestamp);
        let stTime =
            timestamp -
            time.getHours() * 60 * 60 * 1000 -
            time.getMinutes() * 60 * 1000 -
            time.getSeconds() * 1000 -
            time.getMilliseconds();
        return stTime;
    }
    let start = formatTime(startStamp); // 开始时间零点
    let end1 = formatTime(endStamp); // 当天零点
    let end2 = end1 - 0 + 24 * 60 * 60 * 1000 - 1; // 当天的23:59
    start = moment(start).format("YYYY-MM-DD HH:mm:ss")
    end2 = moment(end2).format("YYYY-MM-DD HH:mm:ss")
    let data = [start, end2]
    return data
}