import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)
var state = sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) : {
  //当前页面名称
  nowName: '',
  //后端需要的默认请求头
  Authorization: '',
  //用户信息
  userInfo: '',
  //sn号
  snId: '',
  //设备信息
  equipInfo: '',
  permissionsList: '',
};
var mutations = {
};
const store = new Vuex.Store({
  state,
  mutations,
  actions: {},
  modules: {}
});
export default store
