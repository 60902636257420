import * as echarts from 'echarts';

let install = function (Vue) {
    Object.defineProperties(Vue.prototype, {
        $chart: {
            get() {
                return {
                    //折线图
                    lineHistory(id, x, y) {
                        this.chart = echarts.init(document.getElementById(id));
                        let color = ['#1890ff']
                        let option = {
                            color: color,
                            backgroundColor: "rgba(0,0,0,0)",
                            tooltip: {
                                trigger: "axis",

                            },
                            xAxis: [{
                                data: x,
                                type: "category",
                                axisLabel: {
                                    showMinLabel: true, // 强制显示最小值标签
                                    showMaxLabel: true, // 强制显示最大值标签
                                },
                                max: function () {
                                    return 10;
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: '#e8e8e8'
                                    }
                                },
                                //分格线
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: true
                                },
                                splitArea: {
                                    show: false
                                },
                                axisLabel: {
                                    rotate: 23,
                                    // interval: 10,
                                    color: '#5a5a5a',
                                    fontSize: '80%',
                                },
                            }],
                            yAxis: [{
                                type: 'value',
                                //分格线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#e8e8e8',
                                        // 虚线
                                        type: "dashed"
                                    }
                                },
                                // y轴
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#2D3C5C'
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                                // y轴字
                                axisLabel: {
                                    interval: 0,
                                    color: '#5a5a5a',
                                    fontSize: '80%'

                                },
                                splitArea: {
                                    show: false
                                },
                            }],
                            series: [
                                {
                                    data: y,
                                    type: 'line',
                                    smooth: true,
                                    showSymbol: false,
                                },
                            ]
                        }
                        this.chart.setOption(option);
                    },
                    //折线图
                    line(id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        let userRegisteredNum = []
                        let activeNum = []
                        let mouthStr = []
                        for (let i in data) {
                            userRegisteredNum.push(data[i].userRegisteredNum)
                            mouthStr.push(data[i].mouthStr)
                            activeNum.push(data[i].activeNum)
                        }
                        console.log(userRegisteredNum);
                        let color = ['#4cca72', '#3ba1ff']
                        let option = {
                            color: color,
                            backgroundColor: "rgba(0,0,0,0)",
                            tooltip: {
                                trigger: 'axis',
                            },
                            xAxis: [{
                                data: mouthStr,
                                type: "category",
                                axisLine: {
                                    lineStyle: {
                                        color: '#e8e8e8'
                                    }
                                },
                                //分格线
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: true
                                },
                                splitArea: {
                                    show: false
                                },
                                axisLabel: {
                                    interval: 0,
                                    color: '#5a5a5a',
                                    fontSize: '80%',
                                },
                            }],
                            yAxis: [{
                                type: 'value',
                                //分格线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#e8e8e8',
                                        // 虚线
                                        type: "dashed"
                                    }
                                },
                                // y轴
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#2D3C5C'
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                                // y轴字
                                axisLabel: {
                                    interval: 0,
                                    color: '#5a5a5a',
                                    fontSize: '80%'

                                },
                                splitArea: {
                                    show: false
                                },
                            }],
                            series: [
                                {
                                    data: userRegisteredNum,
                                    type: 'line',
                                    smooth: true,
                                    name: '注册用户'
                                },
                                {
                                    data: activeNum,
                                    type: 'line',
                                    smooth: true,
                                    name: '激活总数'
                                },
                            ]
                        }
                        this.chart.setOption(option);
                    },

                    // 折线柱状图
                    lineBar(id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        let costSum = []
                        let costNum = []
                        let mouth = []
                        for (let i in data) {
                            costSum.push(data[i].costSum)
                            costNum.push(data[i].costNum)
                            mouth.push(data[i].mouth)
                        }
                        let color = ['#4cca72', '#3ba1ff']
                        let option = {
                            color: color,
                            backgroundColor: "rgba(0,0,0,0)",
                            tooltip: {
                                trigger: "axis",
                                formatter: "{b} " + "</br>" + "续费总金额" + " {c}"
                            },
                            xAxis: [{
                                data: mouth,
                                type: "category",
                                axisLine: {
                                    lineStyle: {
                                        color: '#e8e8e8'
                                    }
                                },
                                //分格线
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: true
                                },
                                splitArea: {
                                    show: false
                                },
                                axisLabel: {
                                    interval: 0,
                                    color: '#5a5a5a',
                                    fontSize: '80%',
                                },
                            }],
                            yAxis: [{
                                type: 'value',
                                //分格线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#e8e8e8',
                                        // 虚线
                                        type: "dashed"
                                    }
                                },
                                // y轴
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#2D3C5C'
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                                // y轴字
                                axisLabel: {
                                    interval: 0,
                                    color: '#5a5a5a',
                                    fontSize: '80%'

                                },
                                splitArea: {
                                    show: false
                                },
                            }],
                            series: [
                                {
                                    data: costSum,
                                    type: 'line',
                                    smooth: true,
                                },
                                {
                                    data: costSum,
                                    name: '本月续费金额',
                                    type: 'bar',
                                    barWidth: 30,

                                }
                            ]
                        }
                        this.chart.setOption(option);
                    },

                    //饼图
                    pie(id, data, name) {
                        let num = ''
                        let numData = []
                        let nameData = []
                        if (name === '入库总数') {
                            num = data.allEquNum
                            numData = [data.activateNum, data.allEquNum - data.activateNum]
                            nameData = ['已激活', '待激活']
                        } else if (name === '激活总数') {
                            num = data.activateNum
                            numData = [data.noExpireNum, data.activateNum - data.noExpireNum]
                            nameData = ['正常', '欠费']
                        } else if (name === '正常总数') {
                            num = data.noExpireNum
                            numData = [data.onlineNum, data.offlineNum]
                            nameData = ['在线', '离线']
                        } else if (name === '在线总数') {
                            num = data.onlineNum
                            numData = [data.onOffNum, data.offNum]
                            nameData = ['开机', '关机']
                        }
                        this.chart = echarts.init(document.getElementById(id));
                        let option = {
                            title: [
                                {
                                    text: name,
                                    x: 'center',
                                    top: '40%',
                                    textStyle: {
                                        color: '#929292',
                                        fontSize: 16,
                                        fontWeight: '50',
                                    },
                                },
                                {
                                    text: num,
                                    x: 'center',
                                    top: '52%',
                                    textStyle: {
                                        fontSize: 16,
                                        color: '#27274a',
                                    },
                                },
                            ],
                            tooltip: {
                                trigger: 'item',
                                formatter: "{b} : {c} ({d}%)"
                            },
                            legend: {
                                top: '90%',
                                icon: 'circle',
                                left: 'center',
                                itemHeight: 10
                            },
                            series: [{
                                type: 'pie',
                                radius: ['40%', '70%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: [{
                                    value: numData[0], name: nameData[0]
                                },
                                {
                                    value: numData[1], name: nameData[1]
                                }],
                                itemStyle: {
                                    borderRadius: 10,
                                    borderColor: '#fff',
                                    borderWidth: 4
                                },
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    normal: {
                                        show: false
                                    }
                                },
                            }],
                            color: ['#3ba1ff', '#4cca72'],
                        };
                        this.chart.setOption(option);
                    },

                    //饼图PRO
                    pieP(id, data) {
                        let name = []
                        let left = '62%'
                        let isShow = true
                        for (let i = 0; i < data.alarmList.length; i += 5) {
                            name.push(data.alarmList.slice(i, i + 5))
                        }
                        if (name.length !== 2) {
                            isShow = false
                            left = '72%'
                        }
                        console.log('name', name);
                        this.chart = echarts.init(document.getElementById(id));
                        let option = {
                            left: 100,
                            title: [
                                {
                                    text: '报警总数',
                                    x: 'center',
                                    // left: '15%',
                                    top: '40%',
                                    textStyle: {
                                        color: '#929292',
                                        fontSize: 16,
                                        fontWeight: '50',
                                    },
                                },
                                {
                                    text: data.allAlarmNum,
                                    x: 'center',
                                    top: '50%',
                                    textStyle: {
                                        fontSize: 16,
                                        color: '#27274a',
                                    },
                                },
                            ],
                            tooltip: {
                                trigger: 'item',
                                formatter: "{b} : {c} ({d}%)"
                            },
                            legend: [{
                                orient: 'vertical',
                                left: left,
                                icon: 'circle',
                                top: 'center',
                                itemHeight: 10,
                                data: name[0]
                            }, {
                                show: isShow,
                                orient: 'vertical',
                                left: '75%',
                                icon: 'circle',
                                top: 'center',
                                itemHeight: 10,
                                data: name[1],
                            }],
                            series: {
                                type: 'pie',
                                // left: -300,
                                radius: ['40%', '70%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                itemStyle: {
                                    borderRadius: 10,
                                    borderColor: '#fff',
                                    borderWidth: 4
                                },
                                label: {
                                    normal: {
                                        show: false
                                    }
                                },
                                labelLine: {
                                    normal: {
                                        show: false
                                    }
                                },
                                data: []
                            },
                            color: ['#4cca72', '#3ba1ff', '#fbd438', '#f04864', '#9860e5', '#37cbcb'],
                        };
                        for (let i in data.alarmList) {
                            if (data.alarmList[i].alarmType === 'E1') {
                                data.alarmList[i].name = '库温传感器故障'
                            } else if (data.alarmList[i].alarmType === 'E2') {
                                data.alarmList[i].name = '化霜传感器故障'
                            } else if (data.alarmList[i].alarmType === 'E3') {
                                data.alarmList[i].name = '超高温报警'
                            } else if (data.alarmList[i].alarmType === 'E4') {
                                data.alarmList[i].name = '超低温报警'
                            } else if (data.alarmList[i].alarmType === 'E5') {
                                data.alarmList[i].name = '化霜超温报警'
                            } else if (data.alarmList[i].alarmType === 'E6') {
                                data.alarmList[i].name = '制冷长时间运行不停机报警'
                            } else if (data.alarmList[i].alarmType === 'E7') {
                                data.alarmList[i].name = '外部报警输入'
                            } else if (data.alarmList[i].alarmType === 'E8') {
                                data.alarmList[i].name = '电流过载和欠载报警'
                            } else if (data.alarmList[i].alarmType === 'E9') {
                                data.alarmList[i].name = '电流错相报警'
                            } else if (data.alarmList[i].alarmType === 'EA') {
                                data.alarmList[i].name = '电流缺相报警'
                            } else if (data.alarmList[i].alarmType === 'EB') {
                                data.alarmList[i].name = '电流不平衡报警'
                            }
                            option.series.data.push({
                                value: data.alarmList[i].num,
                                name: data.alarmList[i].name
                            })
                        }
                        this.chart.setOption(option);
                    },

                    //漏斗图
                    funnel(id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        let option = {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {c}',
                            },
                            color: ['#1890ff', '#2fc25a', '#facc14', '#233273', '#8544de'],
                            legend: {
                                data: [
                                    '入库',
                                    '激活',
                                    '正常',
                                    '在线',
                                    '开机'
                                ],
                                orient: 'vertical',
                                bottom: 60,
                                right: 20,
                                icon: 'circle',
                                itemWidth: 8,
                                itemHeight: 8,
                                itemGap: 12,
                                borderRadius: 4,
                                textStyle: {
                                    fontSize: 12,
                                },
                            },
                            series: [
                                {
                                    name: '漏斗图',
                                    type: 'funnel',
                                    left: '4%',
                                    width: '60%',
                                    minSize: '30%',
                                    top: 10,
                                    gap: 2,
                                    label: {
                                        normal: {
                                            show: true,
                                            position: 'inside',
                                            textStyle: {
                                                fontSize: 15,
                                            },
                                            formatter: '{c}',
                                        },
                                    },
                                    labelLine: {
                                        normal: {
                                            length: 10,
                                            lineStyle: {
                                                width: 1,
                                                type: 'solid',
                                            },
                                        },
                                    },
                                    itemStyle: {
                                        normal: {
                                            borderColor: '#fff',
                                            borderWidth: 0,
                                        },
                                    },
                                    data: [
                                        { name: '入库', value: data.allEquNum },
                                        { name: '激活', value: data.activateNum },
                                        { name: '正常', value: data.noExpireNum },
                                        { name: '在线', value: data.onlineNum },
                                        { name: '开机', value: data.onOffNum },
                                    ],
                                },
                            ],
                        }

                        this.chart.setOption(option);
                    }
                }
            }
        },
    })
}
export default {
    install
}
