import request from "../request"
//查询不续费设备列表
function getNoRenewalList(data) {
    return request({
        method: 'post',
        url: '/equipment/getNoRenewalList',
        data
    })
}
//导出不续费设备列表
function exportNoRenewalList(data) {
    return request({
        method: 'post',
        url: '/file/exportNoRenewalList',
        data,
        responseType: "blob",
    })
}
export {
    getNoRenewalList, exportNoRenewalList
}

