<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <el-tabs
      v-model="oneName"
      class="flex-start"
      type="card"
      @tab-click="handleClick(oneName)"
    >
      <el-tab-pane label="近一天" name="day"></el-tab-pane>
      <el-tab-pane label="近七天" name="week"></el-tab-pane>
      <el-tab-pane label="近三十天" name="mouth"></el-tab-pane>
      <el-tab-pane label="自定义" name="diy"> </el-tab-pane>
      <div style="margin-left: 20px">
        <el-form
          :inline="true"
          :model="searchArea"
          class="formInline"
          label-width="120px"
        >
          <el-row :gutter="100">
            <el-col :span="14" v-if="oneName === 'diy'">
              <el-form-item label="自定义时间：">
                <el-date-picker
                  size="small"
                  v-model="searchArea.time"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item
                ><el-button
                  v-if="oneName === 'diy'"
                  type="primary"
                  size="small"
                  @click="onSubmit"
                  >查询</el-button
                ><el-button
                  icon="el-icon-download"
                  type="primary"
                  size="small"
                  @click="download"
                  >Excel导出</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-tabs>
    <el-tabs v-model="twoName" @tab-click="handleClick">
      <el-tab-pane label="图表统计" name="charts">
        <div id="echarts"></div>
      </el-tab-pane>
      <el-tab-pane label="列表统计" name="list">
        <!--        表格区域-->
        <div class="table" style="margin-top: 10px">
          <el-table
            :header-cell-style="{ background: 'rgb(250,250,250)' }"
            v-loading="loading"
            :data="currentPageData"
            stripe
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <el-table-column
              prop="temperature"
              label="温度℃"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="inTime"
              label="采集时间"
              align="center"
              min-width="100"
            >
            </el-table-column>
          </el-table>
        </div>
        <!--        分页区域-->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="searchArea.current"
            :page-sizes="[8, 16, 24, 32, 40]"
            :page-size="searchArea.size"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination></div
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  historyData,
  download,
  echartsData,
} from "@/tools/equipment/equipManage";
import { timing } from "../../components/time";
export default {
  name: "equipTest",
  data() {
    return {
      oneName: "day",
      twoName: "charts",
      //查询数据
      searchArea: {
        //表格宽度
        size: 8,
        //当前多少页
        current: 1,
        time: [],
      },
      //表格数据
      tableData: [],
      //表格数据总数
      total: 0,
      //表格loading
      loading: false,
      show: false,
      selectDate: "",
      pageData: [],
      currentPageData: [],
      //起止时间数据控制
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== "") {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        },
        // disabledDate(time) {
        //   return time.getTime() > Date.now() - 8.64e6;
        // },
      },
    };
  },
  created() {
    this.$store.state.nowName = "历史记录";
    this.searchArea.time = [
      new Date(new Date() - 3600 * 1000 * 24 * 1),
      new Date(),
    ];
    // this.getData();
    this.getEchartsData();
  },
  methods: {
    handleClick(name) {
      if (name === "day") {
        this.searchArea.time = [
          new Date(new Date() - 3600 * 1000 * 24 * 1),
          new Date(),
        ];
        this.getData();
        this.getEchartsData();
      } else if (name === "week") {
        this.searchArea.time = [
          new Date(new Date() - 3600 * 1000 * 24 * 6),
          new Date(),
        ];
        this.getData();
        this.getEchartsData();
      } else if (name === "mouth") {
        this.searchArea.time = [
          new Date(new Date() - 3600 * 1000 * 24 * 29),
          new Date(),
        ];
        this.getData();
        this.getEchartsData();
      } else if (name === "diy") {
        this.searchArea.time = [];
      }
    },
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      // this.getData();
      this.getEchartsData();
    },
    paginationChange() {
      if (this.total < this.searchArea.size) {
        console.log("12");
        this.currentPageData = this.tableData;
      } else {
        this.pageData = [];
        this.currentPageData = [];
        let index = 0;
        for (let i = 0; i < this.total; i++) {
          if (i % this.searchArea.size === 0 && i !== 0) {
            // 可以被 8 整除
            this.pageData.push(this.tableData.slice(index, i));
            index = i;
          }
          if (i + 1 === this.total) {
            this.pageData.push(this.tableData.slice(index, i + 1));
          }
        }
        this.currentPageData = this.pageData[this.searchArea.current - 1];
      }
    },
    //获取表格数据
    getData() {
      let data = { ...this.searchArea };
      data.id = this.$store.state.equipInfo.id;
      if (data.time != null && data.time.length != 0) {
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      } else {
        this.$message.error("请选择时间！已帮您默认选择近一周时间！");
        this.searchArea.time = [
          new Date(new Date() - 3600 * 1000 * 24 * 6),
          new Date(),
        ];
        data.time = this.searchArea.time;
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      }
      // historyData(data).then((res) => {
      //   if (res.data.msg != "success") {
      //     this.$message.error(res.data.msg);
      //   } else {
      //     this.tableData = [];
      //     if (res.data.data.records.length !== 0) {
      //       this.tableData = res.data.data.records;
      //       this.total = res.data.data.total;
      //       this.loading = false;
      //     } else {
      //       this.tableData = [];
      //       this.total = 0;
      //       this.$message.error("暂无数据！");
      //       this.loading = false;
      //     }
      //   }
      // });
    },
    getEchartsData() {
      let data = { ...this.searchArea };
      data.id = this.$store.state.equipInfo.id;
      data.size = "-1";
      if (data.time != null && data.time.length != 0) {
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      } else {
        this.$message.error("请选择时间！已帮您默认选择近一周时间！");
        this.searchArea.time = [
          new Date(new Date() - 3600 * 1000 * 24 * 6),
          new Date(),
        ];
        data.time = this.searchArea.time;
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      }
      echartsData(data).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          if (res.data.data.sourceList.length != 0) {
            let x = res.data.data.xlist;
            let y = res.data.data.ylist;
            this.$nextTick(() => {
              this.$chart.lineHistory("echarts", x, y);
            });
            this.tableData = res.data.data.sourceList;
            this.total = res.data.data.sourceList.length;
            this.paginationChange();
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$message.error("暂无数据！");
            this.loading = false;
            this.paginationChange();
            this.$nextTick(() => {
              this.$chart.lineHistory("echarts", [], []);
            });
          }
        }
      });
    },
    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      // this.loading = true;
      this.paginationChange();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      // this.loading = true;
      this.paginationChange();
    },
    download() {
      let data = { ...this.searchArea };
      data.id = this.$store.state.equipInfo.id;
      if (data.time != null && data.time.length != 0) {
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      } else {
        this.$message.error("请选择时间！已帮您默认选择近一周时间！");
        this.searchArea.time = [
          new Date(new Date() - 3600 * 1000 * 24 * 6),
          new Date(),
        ];
        data.time = this.searchArea.time;
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      }
      download(data).then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let fileName = "历史记录.xls";
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // for Non-IE (chrome, firefox etc.)
          let fileName = "历史记录.xls";
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      });
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 0;
}

>>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-bottom: 1px solid #e4e7ed;
}
>>> .el-tabs--card > .el-tabs__header {
  border: none;
}
#echarts {
  margin: 0 1%;
  width: 98%;
  height: 600px;
}
</style>
