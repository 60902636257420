import request from "../request"
//查询设备数据
function getPage(data) {
    return request({
        method: 'post',
        url: '/equipment/page',
        data
    })

}
//编辑设备
function save(data) {
    return request({
        method: 'post',
        url: '/equipment/save',
        data
    })

}
//获取设备详情
function info(data) {
    return request({
        method: 'post',
        url: '/equipment/info',
        data
    })

}
//历史数据
function historyData(data) {
    return request({
        method: 'post',
        url: '/equipment/history/data',
        data
    })

}
//echarts数据
function echartsData(data) {
    return request({
        method: 'post',
        url: '/wx/history/data',
        data
    })

}
//查询权限列表
function query(data) {
    return request({
        method: 'post',
        url: '/equipment/role/query',
        data
    })
}
//编辑权限列表
function querySave(data) {
    return request({
        method: 'post',
        url: '/equipment/role/save',
        data
    })
}
//返厂重制
function clear(data) {
    return request({
        method: 'post',
        url: '/equipment/clear',
        data
    })
}
//实时数据
function realData(data) {
    return request({
        method: 'post',
        url: '/equipment/real/data',
        data
    })
}
//导出
function download(data) {
    return request({
        method: 'post',
        url: '/file/history/data/download',
        data,
        responseType: "blob",
    })
}
export {
    download, getPage, save, info,
    historyData, realData, echartsData,
    query, querySave, clear
}

