<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <el-form
      :inline="true"
      :model="searchArea"
      class="formInline"
      label-width="80px"
    >
      <el-row :gutter="80">
        <el-col :span="5">
          <el-form-item label="设备编号：">
            <el-input
              v-model="searchArea.sn"
              size="small"
              placeholder="请输入设备编号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="监控点名称：">
            <el-input
              v-model="searchArea.monitorName"
              size="small"
              placeholder="请输入监控点名称"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="8">
          <el-form-item label="缴费时间：">
            <el-date-picker
              size="small"
              v-model="searchArea.time"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="onSubmit"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '查询'
                ) != -1
              "
              >查询</el-button
            >
            <el-button
              size="small"
              @click="reset"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '查询'
                ) != -1
              "
              >重置</el-button
            >
            <el-button
              style="margin-left: 30px"
              type="primary"
              icon="el-icon-download"
              size="small"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '导出'
                ) != -1
              "
              @click="download"
              >Excel导出</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--        表格区域-->
    <div class="table">
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        :data="tableData"
        v-loading="loading"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="sn" label="设备编号" min-width="150">
        </el-table-column>
        <el-table-column prop="monitorName" label="监控点名称" min-width="150">
        </el-table-column>
        <el-table-column prop="nikeName" label="充值用户" min-width="150">
        </el-table-column>
        <el-table-column prop="phoneNo" label="手机号码" min-width="150">
        </el-table-column>
        <el-table-column prop="createTime" label="缴费时间" min-width="150">
          <template slot-scope="scope">
            <div>
              {{ $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--        分页区域-->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchArea.current"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="searchArea.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPage, exportOrderList } from "../../tools/recharge/renew";
import { timing } from "../../components/time";
export default {
  name: "offline",
  data() {
    return {
      //查询数据
      searchArea: {
        sn: "",
        time: "",
        monitorName: "",
        //表格宽度
        size: 5,
        //当前多少页
        current: 1,
      },
      //起止时间数据控制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      //表格数据
      tableData: [],
      //表格数据总数
      total: 0,
      //表格loading
      loading: false,
      permissionsList: [],
    };
  },
  created() {
    this.$store.state.nowName = "续费记录";
    this.permissionsList = this.$store.state.permissionsList;
    console.log(this.permissionsList);
    this.getTableData();
  },
  methods: {
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      this.getTableData();
    },
    //重置查询参数
    reset() {
      this.searchArea.time = "";
      this.searchArea.monitorName = "";
      this.searchArea.sn = "";
    },
    //获取表格数据
    getTableData() {
      let data = { ...this.searchArea };
      if (data.time != null && data.time.length != 0) {
        let timeData = timing(data.time);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      }
      getPage(data).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.tableData = [];
          if (res.data.data != null) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    download() {
      exportOrderList({
        sn: this.searchArea.sn,
        monitorName: this.searchArea.monitorName,
      }).then((res) => {
        console.log(res);
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let fileName = "缴费记录.xls";
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // for Non-IE (chrome, firefox etc.)
          let fileName = "缴费记录.xls";
          const elink = document.createElement("a");
          console.log(elink);
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      });
    },
    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      this.loading = true;
      this.getTableData();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      this.loading = true;
      this.getTableData();
    },
  },
};
</script>

<style scoped>
/*搜素区域距离底部距离*/
.el-row {
  margin-bottom: 20px;

  & :last-child {
    margin-bottom: 0;
  }
}

/*设置form表单子元素宽度*/
.content-wrapper >>> .el-form--inline .el-form-item__content {
  width: 75% !important;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}
</style>
