<template>
  <div class="content-wrapper" v-cloak>
    <!--        搜索区域-->
    <el-form
      :inline="true"
      :model="searchArea"
      class="formInline"
      label-width="90px"
    >
      <el-row :gutter="80">
        <el-col :span="6">
          <el-form-item label="设备编号：">
            <el-input
              v-model="searchArea.sn"
              size="small"
              placeholder="请输入设备编号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="测试状态：">
            <el-select
              v-model="searchArea.testStatus"
              size="small"
              placeholder="请输入测试状态"
              clearable
            >
              <el-option label="测试通过" value="1"></el-option>
              <el-option label="待测试" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="8">
          <el-form-item label="测试时间：">
            <el-date-picker
              size="small"
              v-model="searchArea.createTime"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="width: 100% !important">
            <el-button
              type="primary"
              size="small"
              @click="onSubmit"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '列表查询'
                ) != -1
              "
              >查询</el-button
            >
            <el-button
              size="small"
              @click="reset"
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '列表查询'
                ) != -1
              "
              >重置</el-button
            >
            <span
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '开启/关闭测试'
                ) != -1
              "
            >
              <el-button
                v-if="test === true"
                style="margin-left: 30px"
                type="primary"
                size="small"
                @click="testOn"
                >开始测试</el-button
              >
              <el-button
                v-else
                style="margin-left: 30px"
                type="danger"
                size="small"
                @click="testOff()"
                >关闭测试</el-button
              >
            </span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--        表格区域-->
    <div class="table roleTable">
      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="sn"
          label="设备编号"
          align="center"
          min-width="100"
        >
        </el-table-column>
        <el-table-column label="测试状态" align="center" min-width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.testStatus === 1">
              <span style="color: #00a854">●</span> 测试通过
            </div>
            <div v-else-if="scope.row.testStatus === 0">
              <span style="color: #f04134">●</span> 待测试
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="测试时间"
          align="center"
          min-width="100"
        >
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              round
              plain
              v-if="
                scope.row.testStatus === 0 &&
                permissionsList.findIndex(
                  (info) => info.menuTitle === '测试'
                ) != -1
              "
              @click="testOnOff(scope.row)"
              >测试
            </el-button>
            <el-button
              size="mini"
              type="primary"
              round
              plain
              v-if="
                scope.row.testStatus === 1 &&
                permissionsList.findIndex(
                  (info) => info.menuTitle === '入库'
                ) != -1
              "
              @click="putInStorage(scope.row)"
              >入库
            </el-button>
            <el-button
              type="danger"
              size="mini"
              round
              plain
              v-if="
                permissionsList.findIndex(
                  (info) => info.menuTitle === '删除'
                ) != -1
              "
              @click="removeById(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--        分页区域-->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchArea.current"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="searchArea.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { timing } from "../../components/time";
import {
  getPage,
  putInStorage,
  removeById,
  status,
  test,
  testOff,
  testOn,
} from "../../tools/equipment/equipTest";
export default {
  name: "equipTest",
  data() {
    return {
      //查询数据
      searchArea: {
        //设备编号
        sn: "",
        //设备状态
        status: "",
        //测试时间
        createTime: "",
        //表格宽度
        size: 5,
        //当前多少页
        current: 1,
      },
      //表格数据
      tableData: [],
      //表格数据总数
      total: 0,
      //表格loading
      loading: false,
      test: true,
      interval: "",
      //起止时间数据控制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        permissionsList: [],
      },
    };
  },
  created() {
    this.$store.state.nowName = "测试设备";
    this.permissionsList = this.$store.state.permissionsList;
    this.getTableData();
    this.status();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    status() {
      status().then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          if (res.data.data === true) {
            testOn().then((res) => {
              if (res.data.msg != "success") {
                this.$message.error(res.data.msg);
              } else {
                this.test = false;
                this.interval = setInterval(() => {
                  this.onSubmit();
                }, 2000);
              }
            });
          } else {
            this.test = true;
          }
        }
      });
    },
    removeById(row) {
      console.log(row);
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeById({ id: row.id }).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.test = false;
              this.onSubmit();
              this.$message.success("删除成功！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    testOnOff(row) {
      test({
        sn: row.sn,
        commandType: "F2",
      }).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.$message.success("测试成功！");
          this.onSubmit();
        }
      });
    },
    putInStorage(row) {
      this.$confirm("确认入库?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          putInStorage(row.id).then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.test = false;
              this.$message.success("入库成功！");
              this.onSubmit();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消入库",
          });
        });
    },
    testOn() {
      this.$confirm("确认打开测试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          testOn().then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.test = false;
              this.interval = setInterval(() => {
                this.onSubmit();
              }, 2000);
              this.$message.success("打开成功！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消开启",
          });
        });
    },
    testOff() {
      this.$confirm("确认关闭测试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          testOff().then((res) => {
            if (res.data.msg != "success") {
              this.$message.error(res.data.msg);
            } else {
              this.test = true;
              this.$message.success("关闭成功！");
              clearInterval(this.interval);
              this.onSubmit();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消关闭",
          });
        });
    },
    //查询
    onSubmit() {
      this.loading = true;
      this.searchArea.current = 1;
      this.getTableData();
    },
    //重置查询参数
    reset() {
      this.searchArea.sn = "";
      this.searchArea.testStatus = "";
      this.searchArea.createTime = "";
    },
    //获取表格数据
    getTableData() {
      let data = { ...this.searchArea };
      if (data.createTime != null && data.createTime.length != 0) {
        let timeData = timing(data.createTime);
        data.startTime = timeData[0];
        data.endTime = timeData[1];
      }
      getPage(data).then((res) => {
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.tableData = [];
          if (res.data.data != null) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        }
      });
    },
    //表格每页展示多少设置
    handleSizeChange(val) {
      this.searchArea.size = val;
      this.searchArea.current = 1;
      this.loading = true;
      this.getTableData();
    },
    //跳转页码  以及页码点击
    handleCurrentChange(val) {
      this.searchArea.current = val;
      this.loading = true;
      this.getTableData();
    },
  },
};
</script>

<style scoped>
/*搜素区域距离底部距离*/
.el-row {
  margin-bottom: 20px;

  & :last-child {
    margin-bottom: 0;
  }
}

/*设置form表单子元素宽度*/
.content-wrapper >>> .el-form--inline .el-form-item__content {
  width: 75% !important;
}

/*设置弹出框内select宽度为100%*/
.content-wrapper >>> .el-select {
  width: 100%;
}

/*表格class*/
.roleTable {
  /*height: 70%;*/
}
</style>
